<template>
  <div class="modal sa-login">
    <div class="modal__wrapper">
      <h1>{{ $t('home.team') }}</h1>
      <div class="modal__body">
        <form v-if="activeManager" action="" @submit.prevent="submitManager">
          <p>{{ activeManager.voornaam }} {{ activeManager.naam }}</p>
          <input type="password" class="input" v-model="password" @submit.prevent="submitManager" :placeholder="$t('login.password')" :name="Math.round(Math.random() * Date.now())">
          <div class="sa-login__controls">
            <button type="button" class="button button--cancel" @click.prevent="clearManager">{{ $t('back') }}</button>
            <button type="submit" class="button" @click="submitManager">{{ $t('login.submit') }}</button>
          </div>
        </form>
        <div v-else class="sa-login__list">
          <div @click="clickUser(item)" class="sa-login__list__item"
               :class="{'sa-login__list__item--manager':parseInt(item.ismanager)}"
               v-for="item in subAccounts"
               :key="item.uid">{{ item.voornaam }} {{ item.naam }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubAccountLogin",
  data() {
    return {
      activeManager: null,
      password: ""
    };
  },
  computed: {
    subAccounts() {
      return this.$store.getters['user/subAccounts'];
    }
  },
  methods: {
    async clickUser(item) {
      if (parseInt(item.ismanager)) {
        this.activeManager = item;
        return;
      }
      try {
        await this.$store.dispatch('user/loginSubAccount', {uid: item.uid, talic: item.talic});
      } catch (e) {
        alert(e);
      }
    },
    clearManager(){
      this.password = "";
      this.activeManager = null;
    },

    async submitManager(){
      if(!this.password){
        return;
      }
      try{
        await this.$store.dispatch('user/loginManager',{uid:this.activeManager.uid,talic:this.activeManager.talic,password:this.password});
      }catch(e){
        alert(e);
      }
    }
  }
}
</script>