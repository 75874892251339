<template>
  <div class="page billodetail">
    <h1>{{ $t('home.menu.vip') }} - PO {{ po }}
      <span class="billodetail__head__controls">
        <button class="back" v-if="!isSaving" @click="$router.push('/vip')"
                :title="$t('back')">
                  <fa-icon icon="arrow-left"></fa-icon>
                </button>
                <button class="cancel" v-if="!isSaving" @click="resetEdits"
                        :title="$t('actions.resetedits')">
                  <fa-icon icon="history"></fa-icon>
                </button>
                <button class="save" v-if="!isSaving" @click="saveOrder"
                        :title="$t('actions.saveedits')">
                  <fa-icon icon="save"></fa-icon>
                </button>
              </span>
    </h1>
    <div class="page__content" v-if="copyOrder">
      <div class="billodetail__controls">
        <button class="button" v-if="order.ritheen && order.ritheen.id"
                @click="toonRit(order.ritheen.id)">{{ $t('actions.showinfo') }}
          {{ $t('input.departure') }}
        </button>
        <button class="button" v-if="order.ritterug && order.ritterug.id"
                @click="toonRit(order.ritterug.id)">{{ $t('actions.showinfo') }}
          {{ $t('input.arrival') }}
        </button>
        <button class="button" @click="print">{{ $t('actions.print') }}</button>
        <button class="button" :class="{'button--disabled':isSending, 'button--accept':isSent==='confirm'}"
                @click="sendMail('confirm')">{{ $t('actions.sendorder') }}
        </button>
      </div>
      <div class="billodetail__data">
        <div class="billodetail__data__trip">
          <h2>{{ $t('data.customer') }}</h2>
          <form action="" @submit.prevent="" class="billodetail__data__trip__form">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered" readonly :value="order[order.passagierheen?'passagierheen':'passagierterug'].passagier">
              <label>{{ $t('input.mobile') }}</label>
              <input type="tel" class="input input--bordered" v-model="copyOrder[order.passagierheen?'passagierheen':'passagierterug'].tel">
              <label>{{ $t('input.email') }}</label>
              <input type="email" class="input input--bordered" v-model="copyOrder[order.passagierheen?'passagierheen':'passagierterug'].custemail">
              <label>{{ $t('input.lang') }}</label>
              <select name="" id="" class="select" v-model="copyOrder[order.passagierheen?'passagierheen':'passagierterug'].lang">
                <option value="N">NL</option>
                <option value="F">FR</option>
                <option value="E">EN</option>
              </select>
              <label>{{ $t('vip.adults') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order[order.passagierheen?'passagierheen':'passagierterug'].ads">
              <label>{{ $t('vip.children') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order[order.passagierheen?'passagierheen':'passagierterug'].chd">
              <label>{{ $t('vip.infants') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order[order.passagierheen?'passagierheen':'passagierterug'].inf">
              <h3>{{ $t('data.remarks') }}</h3><span></span>
              <label>SSR1:</label><p><b>{{order[order.passagierheen?'passagierheen':'passagierterug'].ssr1}} {{order[order.passagierheen?'passagierheen':'passagierterug'].qssr1}}</b></p>
              <label>SSR2:</label><p><b>{{order[order.passagierheen?'passagierheen':'passagierterug'].ssr2}} {{order[order.passagierheen?'passagierheen':'passagierterug'].qssr2}}</b></p>
              <label>SSR3:</label><p><b>{{order[order.passagierheen?'passagierheen':'passagierterug'].ssr3}} {{order[order.passagierheen?'passagierheen':'passagierterug'].qssr3}}</b></p>
            </div>
          </form>
        </div>
        <div class="billodetail__data__trip">
          <h2>Agent</h2>
          <form action="" @submit.prevent="" class="billodetail__data__trip__form">
            <div class="billodetail__data__trip__form__column">
              <label>Travelagent</label>
              <input type="text" class="input input--bordered" readonly v-model="order[order.passagierheen?'passagierheen':'passagierterug'].travelagent">
              <label>ID</label>
              <input type="text" class="input input--bordered" readonly v-model="order[order.passagierheen?'passagierheen':'passagierterug'].talic">
              <label>Tel.</label>
              <input type="tel" class="input input--bordered" readonly v-model="order[order.passagierheen?'passagierheen':'passagierterug'].taphone">
              <label>Email</label>
              <input type="email" class="input input--bordered" readonly v-model="order[order.passagierheen?'passagierheen':'passagierterug'].taemail">
              <h3>TUI</h3><span></span>
              <label :class="{'label--error':copyOrder.jetair.geweigerd}">{{ $t('vip.refused') }}</label>
              <input id="vipcancel" type="checkbox" v-model="copyOrder.jetair.geweigerd">
              <label></label>
              <div class="textarea" v-html="order.jetair.changedfields"></div>
            </div>
          </form>
        </div>
        <div class="billodetail__data__trip">
          <h2>{{ $t('input.departure') }}</h2>
          <form action="" @submit.prevent="" class="billodetail__data__trip__form" v-if="order.ritheen && order.ritheen.id">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.date') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritheen.datum">
              <label>Pickup</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritheen.tijd">
              <p class="wide" v-if="order.ritheen.tijd ==='00:00'">{{$t('data.timing')}}</p>
              <label>{{ $t('input.airport') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.passagierheen.airport">
              <label>{{ $t('input.flight') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.passagierheen.vluchtnr">
              <label>{{$t('data.early')}}</label>
              <input type="number" step="0.5" class="input input--bordered input--nr" v-model.number="copyOrder.passagierheen.opluchthaven">
              <label for="viphotel">{{ $t('input.hotel') }}</label>
              <input id="viphotel" type="checkbox" v-model="copyOrder.ritheen.pohotel">
              <h3>{{ $t('vip.pickup') }}</h3><span></span>
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered" v-model="copyOrder.ritheen.vannaam">
              <label>{{ $t('input.street') }} + {{ $t('input.nr') }}</label>
              <div>
                <input type="text" class="input input--bordered" v-model="copyOrder.ritheen.vanstraat">
                <input type="text" class="input input--bordered number" v-model="copyOrder.ritheen.vannummer">
              </div>
              <label>{{ $t('input.zip') }}</label>
              <input type="text" class="input input--bordered" v-model="copyOrder.ritheen.vanpostnr">
              <label>{{ $t('input.city') }}</label>
              <input type="text" class="input input--bordered" v-model="copyOrder.ritheen.vanstad">
              <label>{{ $t('input.country') }}</label>
              <select class="select" v-model="copyOrder.ritheen.vanland">
                <option value="">{{ $t('input.countries.be') }}</option>
                <option value="NEDERLAND">{{ $t('input.countries.nl') }}</option>
                <option value="FRANKRIJK">{{ $t('input.countries.fr') }}</option>
                <option value="DUITSLAND">{{ $t('input.countries.de') }}</option>
                <option value="LUXEMBURG">{{ $t('input.countries.lu') }}</option>
              </select>
              <p class="error" v-if="copyOrder.ritheen.pohotel">{{ $t('vip.contacthes') }}</p>
              <p class="error" v-if="copyOrder.ritheen.vanland">{{ $t('vip.contacthesprice') }}</p>
            </div>
          </form >
          <div class="billodetail__data__trip__form" v-else>
            <p>No booking.</p>
          </div>
          <template v-if="order.ritheen && order.ritheen.id && history.heen">
            <h3>{{ $t('data.history') }}</h3>
            <div class="billodetail__data__trip__history">
              <table class="table">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column">{{ $t('data.date') }}</th>
                  <th class="table__header__column">{{ $t('data.description') }}</th>
                  <th class="table__header__column">{{ $t('input.name') }}</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" v-for="(item,index) in history.heen" :key="`HH-${index}`">
                  <td class="table__body__item__column">{{ $d(new Date(item.CREATIONDATE), 'hes') }}</td>
                  <td class="table__body__item__column" v-html="item.VERSLAG"></td>
                  <td class="table__body__item__column">{{ item.NAAM }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
        <div class="billodetail__data__trip">
          <h2>{{ $t('back') }}</h2>
          <form action="" @submit.prevent="" class="billodetail__data__trip__form" v-if="order.ritterug && order.ritterug.id">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.date') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritterug.datum">
              <label>Pickup</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritterug.tijd">
              <p class="wide" v-if="order.ritterug.tijd ==='00:00'">{{$t('data.timing')}}</p>
              <label>{{ $t('input.airport') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.passagierterug.airport">
              <label>{{ $t('input.flight') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.passagierterug.vluchtnr">
              <label>Hotel</label>
              <span>/</span>
              <h3>{{ $t('vip.dropoff') }}</h3><span></span>
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered" v-model="copyOrder.ritterug.naarnaam">
              <label>{{ $t('input.street') }} + {{ $t('input.nr') }}</label>
              <div>
                <input type="text" class="input input--bordered" v-model="copyOrder.ritterug.naarstraat">
                <input type="text" class="input input--bordered number" v-model="copyOrder.ritterug.naarnummer">
              </div>
              <label>{{ $t('input.zip') }}</label>
              <input type="text" class="input input--bordered" v-model="copyOrder.ritterug.naarpostnr">
              <label>{{ $t('input.city') }}</label>
              <input type="text" class="input input--bordered" v-model="copyOrder.ritterug.naarstad">
              <label>{{ $t('input.country') }}</label>
              <select class="select" v-model="copyOrder.ritterug.naarland">
                <option value="">{{ $t('input.countries.be') }}</option>
                <option value="NEDERLAND">{{ $t('input.countries.nl') }}</option>
                <option value="FRANKRIJK">{{ $t('input.countries.fr') }}</option>
                <option value="DUITSLAND">{{ $t('input.countries.de') }}</option>
                <option value="LUXEMBURG">{{ $t('input.countries.lu') }}</option>
              </select>
              <p class="error" v-if="copyOrder.ritterug.naarland">{{ $t('vip.contacthesprice') }}</p>
            </div>
          </form>
          <div class="billodetail__data__trip__form" v-else>
            <p>No booking.</p>
          </div>
          <template v-if="order.ritterug && order.ritterug.id && history.terug">
            <h3>{{ $t('data.history') }}</h3>
            <div class="billodetail__data__trip__history">
              <table class="table">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column">{{ $t('data.date') }}</th>
                  <th class="table__header__column">{{ $t('data.description') }}</th>
                  <th class="table__header__column">{{ $t('input.name') }}</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" v-for="(item,index) in history.terug" :key="`HH-${index}`">
                  <td class="table__body__item__column">{{ $d(new Date(item.CREATIONDATE), 'hes') }}</td>
                  <td class="table__body__item__column" v-html="item.VERSLAG"></td>
                  <td class="table__body__item__column">{{ item.NAAM }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "VipDetail",

  data() {
    return {
      order: null,
      copyOrder: null,
      history: {
        heen: [],
        terug: []
      },

      isSending: false,
      isSent: false,
      sentTimeout: null,

      isSaving: false
    };
  },

  created() {
    if (!this.hasVip) {
      this.$router.replace('/');
      return;
    }
    this.fetchData();
  },

  beforeDestroy() {
    clearTimeout(this.sentTimeout);
  },

  computed: {
    hasVip() {
      return this.$store.getters['user/hasVip'];
    },

    po() {
      return this.$route.params.po;
    }
  },

  methods: {
    async fetchData() {
      try {
        this.order = await this.$store.dispatch('vip/getOrderDetail', {po: this.po});
        this.copyOrder = JSON.parse(JSON.stringify(this.order));
        this.fetchHistory(this.order.ritheen?.id, this.order.ritterug?.id);
      } catch (e) {
        alert(e);
      }
    },

    async fetchHistory(heen, terug) {
      try {
        if(heen) {
          this.history.heen = await this.$store.dispatch('vip/getOrderHistory', heen);
        }
        if(terug) {
          this.history.terug = await this.$store.dispatch('vip/getOrderHistory', terug);
        }
      } catch (e) {
        alert(e);
      }
    },

    resetEdits(){
      this.copyOrder = JSON.parse(JSON.stringify(this.order));
    },

    print() {
      const pas = this.order.passagierheen?this.order.passagierheen:this.order.passagierterug;
      const link = process.env.VUE_APP_ROOT_API + process.env.VUE_APP_ROOT_API_ENV + "/templates/vipdriveconfirmation.php?id=" + this.po + "&taal=" + pas.lang.toUpperCase() + "&access_token=" + this.$store.getters.token;
      const encoded = escape(link);
      const dataLink = process.env.VUE_APP_ROOT_API + process.env.VUE_APP_ROOT_API_ENV + "/pdf/generate_pdf.json?url=" + encoded;
      window.open(dataLink);
    },

    toonRit(id) {
      const link = process.env.VUE_APP_ROOT_API + process.env.VUE_APP_ROOT_API_ENV + "/templates/billorit.php?id=" + id + "&taal=" + "NL" + "&access_token=" + this.$store.getters.token;
      window.open(link);
    },

    async sendMail(type) {
      this.isSending = true;
      const pas = this.order.passagierheen?this.order.passagierheen:this.order.passagierterug;
      const dir = type === 'T' ? 'terug' : 'heen';
      const id = type === 'confirm' ? this.po : this.order['rit' + dir].id;
      const template = type === 'confirm' ? 'vipdriveconfirmation' : 'vippickup';
      try {
        await this.$store.dispatch('vip/sendMail', {
          id,
          lang: pas.lang.toUpperCase(),
          template,
          profile: 'HES',
          emailto: pas.custemail
        });
        this.isSent = type;
        this.sentTimeout = setTimeout(() => {
          this.isSent = false;
        }, 500);
      } catch (e) {
        alert(e);
      }
      this.isSending = false;
    },

    async saveOrder() {
      this.isSaving = true;
      const hasH = this.order.ritheen && this.order.ritheen.id;
      const hasT = this.order.ritterug && this.order.ritterug.id;
      const data = {
        geweigerd: this.copyOrder.jetair?parseInt(this.copyOrder.jetair.geweigerd):null,
        po: this.po,
        talic: this.order[hasH?'passagierheen':'passagierterug'].talic,
        taemail: this.order[hasH?'passagierheen':'passagierterug'].taemail,
        passagier: this.copyOrder[hasH?'passagierheen':'passagierterug'].passagier,
        tel: this.copyOrder[hasH?'passagierheen':'passagierterug'].tel,
        custemail: this.copyOrder[hasH?'passagierheen':'passagierterug'].custemail,
        lang: this.copyOrder[hasH?'passagierheen':'passagierterug'].lang.toUpperCase()
      }
      if(hasH){
        data.heenhotel= parseInt(this.copyOrder.ritheen.pohotel);
        data.idritheen= this.order.ritheen.id;
        data.vannaamheen= this.copyOrder.ritheen.vannaam;
        data.vanstraatheen= this.copyOrder.ritheen.vanstraat;
        data.vannummerheen= this.copyOrder.ritheen.vannummer;
        data.vanpostnrheen= this.copyOrder.ritheen.vanpostnr;
        data.vanstadheen= this.copyOrder.ritheen.vanstad;
        data.vanlandheen= this.copyOrder.ritheen.vanland;
        data.opluchthavenheen= this.copyOrder.passagierheen.opluchthaven;
      }
      if(hasT){
        data.terughotel = "0";
        data.idritterug= this.order.ritterug.id;
        data.naarnaamterug= this.copyOrder.ritterug.naarnaam;
        data.naarstraatterug= this.copyOrder.ritterug.naarstraat;
        data.naarnummerterug= this.copyOrder.ritterug.naarnummer;
        data.naarpostnrterug= this.copyOrder.ritterug.naarpostnr;
        data.naarstadterug= this.copyOrder.ritterug.naarstad;
        data.naarlandterug= this.copyOrder.ritterug.naarland;
        data.opluchthaventerug= this.copyOrder.passagierterug.opluchthaven;
      }

      try {
        await this.$store.dispatch('vip/saveOrder', data);
        this.fetchData();
      } catch (e) {
        alert(e);
      }
      this.isSaving = false;
    }
  }
}
</script>