import Vue from 'vue';
import VueX from 'vuex';

Vue.use(VueX);

import authModule from './modules/auth';
import userModule from './modules/user';
import messageModule from './modules/messages';
import managementModule from './modules/management';
import commissionModule from './modules/commission';
import billoModule from './modules/billo';
import vipModule from './modules/vip';
import pegaseModule from './modules/pegase';
import groupsModule from './modules/groups';
import commonModule from './modules/common';

export const store = new VueX.Store({
    modules: {
        auth: authModule,
        user: userModule,
        messages: messageModule,
        management: managementModule,
        commission: commissionModule,
        billo: billoModule,
        vip: vipModule,
        pegase: pegaseModule,
        groups: groupsModule,
        common: commonModule
    }
});