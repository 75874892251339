export default{
    async checkAddress(context, payload){
        const token = context.rootGetters.token;
        if(Object.keys(payload).some(key=>!payload[key])){
            throw new Error("Not all address information provided");
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/locations/geocode/${payload.street} ${payload.postal} ${payload.city} ${payload.country}`;
        const response = await fetch(url,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status || !responseData.data || !responseData.data.FORMATTED_ADDRESS) {
            const error = new Error(
                responseData.error_description || 'Failed to validate address.'
            );
            throw error;
        }
    },

    async preview(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${payload.id}?template=${payload.template}&taal=${payload.taal}&pdf=${payload.pdf}`;
        const response = await fetch(url,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status || !responseData.data) {
            const error = new Error(
                responseData.error_description || 'Failed to launch preview.'
            );
            throw error;
        }
        return responseData.data;
    }
}