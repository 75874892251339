export default {
    async getTalicData(context){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leesprofile?talic=${talic}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch talic data.'
            );
            throw error;
        }
        context.commit('setTalicData', responseData.data);
        context.dispatch('getTalicSubs');
    },

    async getTalicSubs(context){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/listactiveusers?talic=${talic}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch talic data.'
            );
            throw error;
        }
        context.commit('setSubAccounts', responseData.data);
    },

    async loginSubAccount(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/loginSubaccountUser?access_token=${token}`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to login subaccount.'
            );
            throw error;
        }
        context.commit('setActiveAccount', responseData.data);
        context.dispatch('getVouchers');
    },

    async loginManager(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/loginSubaccountManager?access_token=${token}`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to login manager.'
            );
            throw error;
        }
        const data = responseData.data;
        data.ismanager = true;
        context.commit('setActiveAccount', responseData.data);
        context.dispatch('getVouchers');
    },

    logoutSub(context){
        context.commit('setActiveAccount', null);
        context.commit('setVouchers', []);
    },

    clearUserData(context){
        context.commit('setTalicData', []);
        context.commit('setActiveAccount', null);
        context.commit('setSubAccounts', []);
        context.commit('setVouchers', []);
        context.commit('commission/setCommissions',[],{root:true});
    },

    async getIncentiveData(context){
        const token = context.rootGetters.token;
        const uid = context.getters.activeSub.uid;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/detailincentive?uid=${uid}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch talic data.'
            );
            throw error;
        }
        return responseData;
    },

    async getVouchers(context){
        const token = context.rootGetters.token;
        const uid = context.getters.activeSub.uid;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leesbillofree?uid=${uid}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch vouchers.'
            );
            throw error;
        }
        context.commit('setVouchers',responseData.data);
    },

    async addVoucher(context, payload){
        const token = context.rootGetters.token;
        const uid = context.getters.activeSub.uid;
        const id = context.getters.activeSub.id;
        const data = {
            uid,
            id,
            bedrag: payload
        };
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/newbillofree?access_token=${token}`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to add voucher.'
            );
            throw error;
        }
        context.commit('decreaseIncentive',payload)
        context.dispatch('getVouchers');
    },

    async resetPasswordUsername(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagentusers/resetpasswordusername?username=${payload}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to reset user password with given username.'
            );
            throw error;
        }
    },

    async resetPasswordEmail(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagentusers/resetpasswordemail?email=${payload}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to reset user password with given email.'
            );
            throw error;
        }
    },

    async checkValidReset(context, {username, code}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagentusers/passwordresetvalid?username=${username}&code=${code}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to verify user password reset.'
            );
            throw error;
        }
    },

    async resetPassword(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagentusers/changepassword?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to reset user password.'
            );
            throw error;
        }
    },

    async checkUserAccess(context, payload){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        payload.talic = talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/checkAccessBooking?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'You have no access to this order.'
            );
            throw error;
        }
    }
}