export default{
    async pay(data, type) {
        let endpoint;
        if (type === 'refund') {
            endpoint='directlinkrefund';
        }
        else if(type=== 'payment'){
            endpoint='directlinkpayment';
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/${endpoint}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to pay user.'
            );
            throw error;
        }
    }
};
