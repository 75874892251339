export const ObjectMixin = {

    getObjectDifference: function(obj1,obj2){
        const o1 = JSON.parse(JSON.stringify(obj1));
        const o2 = JSON.parse(JSON.stringify(obj2));
        let diff = Object.keys(o2).reduce((diff, key) => {
            if (o1[key].toString() === o2[key].toString()) return diff;
            return {
                ...diff,
                [key]: o2[key]
            }}, {});
        return diff;
    },

    convertObjectKeyValue: function(obj, old){
        return Object.keys(obj).map(a => a==='DATUM'?({fieldname:a,newvalue:this.formatDateSql(obj[a]),oldvalue:this.formatDateSql(old[a])}):({fieldname:a,newvalue:obj[a],oldvalue:old[a]}));
    },

    formatDateSql: function(date){
        return date.split('/')[2] + "/" + date.split('/')[1] + "/" + date.split('/')[0];
    }
};