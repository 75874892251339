<template>
  <div class="page free">
    <h1>{{ $t('home.menu.free') }}</h1>
    <div class="page__content">
      <div class="free__info" v-html="$t('free.text')"></div>
      <div class="free__data">
        <h3>{{ $t('free.vouchers') }}</h3>
        <div class="free__data__new">
          <label for="freebalance">{{ $t('free.balance') }} (€)</label>
          <input id="freebalance" class="input input--bordered" type="text" v-model="saldo">
          <button class="button" :class="{'button--disabled':!saldoValid}" @click="addVoucher">
            {{ $t('free.createvoucher') }}
          </button>
        </div>
        <div class="free__data__orders">
          <table class="table">
            <thead>
            <tr class="table__header">
              <th class="table__header__column">{{ $t('free.code') }}</th>
              <th class="table__header__column">{{ $t('free.amount') }}</th>
              <th class="table__header__column">{{ $t('free.valid') }}</th>
              <th class="table__header__column">{{ $t('free.used') }}</th>
            </tr>
            </thead>
            <tbody class="table__body">
            <tr class="table__body__item" @click="printVoucher(item.CODE)"
                :class="{'orders__body__item--disabled':item.GEBRUIKT}" v-for="item in vouchers"
                :key="item.CODE">
              <td class="table__body__item__column">{{ item.CODE }}</td>
              <td class="table__body__item__column">€{{ item.VASTEKORTING }}</td>
              <td class="table__body__item__column">{{ item.TOT }}</td>
              <td class="table__body__item__column">{{ item.GEBRUIKT }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Free",
  data() {
    return {
      saldo: 0
    };
  },
  computed: {
    hasFree(){
      return this.$store.getters['user/hasFree'];
    },
    activeSub() {
      return this.$store.getters['user/activeSub'];
    },
    vouchers() {
      return this.$store.getters['user/vouchers'];
    },
    hasVouchers() {
      return this.$store.getters['user/hasVouchers'];
    },
    saldoValid() {
      return parseFloat(this.saldo) <= parseFloat(this.activeSub.incentive) && parseFloat(this.saldo) > 0;
    }
  },

  created() {
    if(!this.hasFree){
      this.$router.replace('/');
      return;
    }
    this.saldo = this.activeSub.incentive;
  },

  methods: {
    printVoucher(code) {
      const link = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/templates/printkortingsbon.php?uid=${this.activeSub.uid}&code=${code}&taal=${this.activeSub.taal}`;
      const encoded = escape(link);
      const dataLink = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pdf/generate_pdf.json?url=${encoded}&access_token=${this.$store.getters.token}`;
      window.open(dataLink);
    },

    async addVoucher() {
      if (!this.saldoValid) {
        return;
      }
      try {
        await this.$store.dispatch('user/addVoucher', this.saldo);
      } catch (e) {
        alert(e);
      }
    }
  }
}
</script>