<template>
  <div class="page messages">
    <h1>{{ $t('home.menu.messages') }}</h1>
    <p v-if="!hasMessages">No messages.</p>
    <div class="messages__list" v-else>
      <div v-for="item in messages" :key="item.id" class="messages__list__item">
        <div class="messages__list__title"><i>{{item.date}}</i> - <b>{{item.title[$i18n.locale]}}</b> <button class="back" @click="toggleMessage(item.id)">{{activeMessage===item.id?$t('collapse'):$t('read-more')}}</button></div>
        <div class="messages__list__text" v-if="activeMessage===item.id" v-html="item.message[$i18n.locale]"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Messages",
  data(){
    return{
      activeMessage: null
    };
  },
  created(){
    this.fetchMessages();
  },

  computed:{
    messages(){
      return this.$store.getters['messages/messages'];
    },
    hasMessages(){
      return this.$store.getters['messages/hasMessages'];
    }
  },

  methods:{
    async fetchMessages(){
      try{
        await this.$store.dispatch('messages/getMessages');
      } catch(e){
        alert(e);
      }
    },
    toggleMessage(id){
      if(this.activeMessage === id){
        this.activeMessage = null;
        return;
      }
      this.activeMessage = id;
    }
  }
}
</script>