export default{
    async getOrders(context, {PageNumber,PageSize,query}){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/listVipSelectionOrders?access_token=${token}`;
        const data = {
            talic,
            PageNumber,
            PageSize,
            PO: query.PO || ""
        }
        const response = await fetch(url,{
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch vip orders.'
            );
            throw error;
        }
        context.commit('setOrders', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / PageSize));
    },

    clearOrders(context){
        context.commit('setOrders', []);
        context.commit('setPages', 0);
    },

    async getOrderDetail(context, {po}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/selectvipbooking?po=${po}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch vip order detail.'
            );
            throw error;
        }
        return responseData.data;
    },

    async getOrderHistory(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leeswijzigingen/${id}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch vip order history.'
            );
            throw error;
        }
        return responseData.data;
    },

    async sendMail(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/sendmail/Sendemailextended?access_token=${token}`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch send vip order email.'
            );
            throw error;
        }
    },

    async saveOrder(context, payload){
        const token = context.rootGetters.token;
        const sub = context.rootGetters['user/activeSub'];
        const talicData = context.rootGetters['user/talicData'];
        if(sub){
            payload.username = `${sub.talic} ${talicData.TANAAM} ${sub.voornaam} ${sub.naam}`;
            payload.userid = sub.uid;
        }else{
            payload.username = `${talicData.TALIC} ${talicData.TANAAM}`;
            payload.userid = talicData.TALIC;
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/saveVipBooking?access_token=${token}`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to save vip order edits.'
            );
            throw error;
        }
    }
}