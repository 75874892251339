<template>
<div class="page">
  <h1>{{ $t('home.menu.management') }}</h1>
  <div class="management">
    <div class="management__info" v-if="info">
      <h3>Info</h3>
      <div>
        <label for="tanaam">{{$t('input.name')}}:</label><input id="tanaam" type="text" class="input input--bordered" v-model="info.TANAAM">
      </div>
      <div>
        <label for="tastr">{{ $t('input.streetnr') }}:</label><input id="tastr" type="text" class="input input--bordered" v-model="info.TASTRAAT">
      </div>
      <div>
        <label for="tazip">{{ $t('input.zip') }}:</label><input id="tazip" type="text" class="input input--bordered" v-model="info.TAPOSTNR">
      </div>
      <div>
        <label for="tacity">{{ $t('input.city') }}:</label><input id="tacity" type="text" class="input input--bordered" v-model="info.TASTAD">
      </div>
      <div>
        <label for="tavat">{{ $t('input.vat') }}:</label><input id="tavat" type="text" class="input input--bordered" v-model="info.TABTWNR">
      </div>
      <div>
        <label for="tatel">{{ $t('input.tel') }}:</label><input id="tatel" type="text" class="input input--bordered" v-model="info.TATEL">
      </div>
      <div>
        <label for="tamail">{{ $t('input.email') }}:</label><input id="tamail" type="text" class="input input--bordered" v-model="info.TAEMAIL">
      </div>

      <button class="button button--cancel" @click="resetChanges">{{ $t('management.resetEdits') }}</button>
      <button class="button button--accept" :class="{'button--disabled':!infoValid}" @click="submitInfo">{{ $t('save') }}</button>
      <div>
      <button class="button" @click="resetPassword">{{ $t('resetpas.title') }}</button>
      </div>
      <p v-if="saved" class="success">{{ $t('saved') }}</p>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
    <div class="management__profiles" v-if="hasSubAccounts && hasUsers">
      <h3>{{$t('management.users')}}</h3>
      <button class="button button--accept" @click="addUser">{{ $t('management.adduser') }} +</button>
      <ul class="management__profiles__list">
        <li v-for="item in users" :key="'profile--'+item.ID" :class="{'error':!item.ACTIEF}" @click="editUser(item)">{{`${item.VOORNAAM}
                    ${item.NAAM}, ${item.FUNCTIE}`}} <button class="back"><fa-icon icon="edit"></fa-icon></button></li>
      </ul>
    </div>
    <div v-if="activeUser" class="management__info">
      <h3>{{ activeUser.ID?$t('management.edituser'):$t('management.newuser') }}</h3>
      <div>
        <label for="eun">{{ $t('input.name') }}</label>
        <input id="eun" type="text" class="input input--bordered" v-model="activeUser.NAAM">
      </div>
      <div>
        <label for="eufn">{{ $t('input.fname') }}</label>
        <input id="eufn" type="text" class="input input--bordered" v-model="activeUser.VOORNAAM">
      </div>
      <div>
        <label for="eulang">{{ $t('input.lang') }}</label>
        <select id="eulang" class="select" v-model="activeUser.TAAL">
          <option>NL</option>
          <option>FR</option>
          <option>EN</option>
        </select>
      </div>
      <div>
        <label for="eufunct">{{$t('input.funct')}}</label>
        <select id="eufunct" class="select" v-model="activeUser.FUNCTIE">
          <option>TRAVEL CONSULTANT</option>
          <option>ASSISTANT OFFICE MANAGER</option>
          <option>OFFICE MANAGER</option>
        </select>
      </div>
      <div v-if="activeUser.FUNCTIE === 'OFFICE MANAGER'">
        <label for="eupass">{{ $t('login.password') }}</label>
        <input id="eupass" type="text" class="input input--bordered" v-model="activeUser.PASWOORD">
      </div>
      <div>
        <label for="euact">{{$t('input.active')}}</label>
        <input id="euact" type="checkbox" class="checkbox" v-model="activeUser.ACTIEF">
      </div>
      <button class="button button--cancel" @click="activeUser=null">{{$t('cancel')}}</button>
      <button class="button button--accept" :class="{'button--disabled':!userValid}" @click="submitUser">{{ $t('save') }}</button>
    </div>
  </div>

  <div class="modal" v-if="isSent">
    <div class="modal__wrapper">
      <h1>{{ $t('resetpas.title') }}</h1>
      <div class="modal__body">
        <p>{{ $t('resetpas.sent') }}</p>
      </div>
      <div class="modal__controls">
        <button class="button" @click="isSent=false">OK</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Management",

  data(){
    return {
      info: JSON.parse(JSON.stringify(this.$store.getters['user/talicData'])),
      activeUser: null,
      saved: false,
      error: null,
      isSent: false
    };
  },

  created(){
    if(!this.hasManagement){
      this.$router.replace('/');
    }
    if(this.hasSubAccounts){
      this.getUsers();
    }
  },

  computed:{
    hasManagement() {
      return this.$store.getters['user/hasManagement'];
    },

    talicData() {
      return this.$store.getters['user/talicData'];
    },

    subAccounts() {
      return this.$store.getters['user/subAccounts'];
    },
    hasSubAccounts() {
      return this.$store.getters['user/hasSubAccounts'];
    },

    users(){
      return this.$store.getters['management/users'];
    },
    hasUsers(){
      return this.$store.getters['management/hasUsers'];
    },

    infoValid(){
      const info = this.info;
      return info
          && info.TANAAM
          && info.TAEMAIL
          && info.TASTRAAT
          && info.TAPOSTNR
          && info.TATEL
          && info.TASTAD;
    },

    userValid(){
      const user = this.activeUser;
      return user && user.NAAM && user.VOORNAAM && !(user.FUNCTIE==='OFFICE MANAGER'&&!user.PASWOORD);
    }
  },

  watch:{
    talicData:{
      deep:true,
      handler(){
        this.resetChanges();
      }
    },
    hasManagement(){
      if(!this.hasManagement){
        this.$router.replace('/');
      }
    }
  },

  methods:{
    resetChanges(){
      this.info = JSON.parse(JSON.stringify(this.talicData));
    },

    async getUsers(){
      try{
        await this.$store.dispatch('management/getUsers');
      } catch(e){
        alert(e);
      }
    },

    async addUser(){
      this.activeUser = {
        TALIC: this.talicData.TALIC,
        ACTIEF: true,
        NAAM: "",
        VOORNAAM: "",
        TAAL: "NL",
        FUNCTIE: "TRAVEL CONSULTANT",
        PASWOORD: ""
      };
    },

    editUser(item){
      this.activeUser = {...item};
    },

    async submitUser(){
      let call = 'management/addUser';
      if(this.activeUser.ID){
        call = 'management/editUser'
      }
      this.activeUser.PASWOORD = this.activeUser.FUNCTIE==='OFFICE MANAGER'?this.activeUser.PASWOORD:"";
      try{
        await this.$store.dispatch(call, this.activeUser);
        this.activeUser = null;
        await this.getUsers();
      }catch(e){
        alert(e);
      }

    },

    async submitInfo(){
      this.saved = false;
      this.error = null;
      if(!this.infoValid){
        return;
      }
      try{
        await this.$store.dispatch('management/saveInfo', this.info);
        this.saved = true;
      }
      catch(e){
        alert(e);
        this.error = e;
      }
    },
    async resetPassword(){
      try {
        await this.$store.dispatch('user/resetPasswordUsername', this.talicData.TALIC);
        this.isSent = true;
      }catch(e){
        alert(e);
      }
    }
  }


}
</script>