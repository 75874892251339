<template>
  <div class="page billodetail">
    <h1>Pegase - PO {{ copyOrder && copyOrder.passagierheen ? copyOrder.passagierheen.po : copyOrder.passagierterug ? copyOrder.passagierterug.po : "" }}
      <span class="billodetail__head__controls">
        <button class="back" v-if="!isSaving" @click="$router.push('/pegase')"
                :title="$t('back')">
                  <fa-icon icon="arrow-left"></fa-icon>
                </button>
                <button class="cancel" v-if="!isSaving" @click="resetEdits"
                        :title="$t('actions.resetedits')">
                  <fa-icon icon="history"></fa-icon>
                </button>
                <button class="save" v-if="!isSaving && cargoValidT && cargoValidH" @click="saveOrder"
                        :title="$t('actions.saveedits')">
                  <fa-icon icon="save"></fa-icon>
                </button>
              </span>
    </h1>
    <div class="page__content" v-if="copyOrder">
      <div class="billodetail__controls">
        <button class="button" v-if="order.ritheen && order.ritheen.id"
                @click="toonRit(order.ritheen.id)">{{ $t('actions.showinfo') }}
          {{ $t('input.departure') }}
        </button>
        <button class="button" v-if="order.ritterug && order.ritterug.id"
                @click="toonRit(order.ritterug.id)">{{ $t('actions.showinfo') }}
          {{ $t('input.arrival') }}
        </button>
        <button class="button" @click="print">{{ $t('actions.print') }}</button>
        <button class="button" @click="isHistoriek=true">{{ $t('data.history') }}</button>
        <button class="button" :class="{'button--disabled':isSending, 'button--accept':isSent==='confirm'}"
                @click="sendMail('confirm')">{{ $t('actions.sendorder') }}
        </button>
      </div>
      <div class="billodetail__data">
        <div class="billodetail__data__trip">
          <h2>{{ $t('data.customer') }}</h2>
          <form action="" @submit.prevent="" class="billodetail__data__trip__form">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered" readonly
                     :value="order[order.passagierheen?'passagierheen':'passagierterug'].passagier">
              <label>{{ $t('input.mobile') }}</label>
              <input type="tel" class="input input--bordered" :readonly="order.readonlyheen || order.readonlyterug"
                     v-model="copyOrder[order.passagierheen?'passagierheen':'passagierterug'].tel">
              <label>{{ $t('input.email') }}</label>
              <input type="email" class="input input--bordered" :readonly="order.readonlyheen || order.readonlyterug"
                     v-model="copyOrder[order.passagierheen?'passagierheen':'passagierterug'].custemail">
              <label>{{ $t('input.lang') }}</label>
              <select name="" id="" class="select" :disabled="order.readonlyheen || order.readonlyterug"
                      v-model="copyOrder[order.passagierheen?'passagierheen':'passagierterug'].lang">
                <option value="NL">NL</option>
                <option value="FR">FR</option>
                <option value="EN">EN</option>
              </select>
              <h3>Agent</h3><span></span>
              <label>Travelagent</label>
              <input type="text" class="input input--bordered" readonly
                     v-model="order[order.passagierheen?'passagierheen':'passagierterug'].travelagent">
              <label>ID</label>
              <input type="text" class="input input--bordered" readonly
                     v-model="order[order.passagierheen?'passagierheen':'passagierterug'].talic">
              <label>Tel.</label>
              <input type="tel" class="input input--bordered" readonly
                     v-model="order[order.passagierheen?'passagierheen':'passagierterug'].taphone">
              <label>Email</label>
              <input type="email" class="input input--bordered" readonly
                     v-model="order[order.passagierheen?'passagierheen':'passagierterug'].taemail">
            </div>
          </form>
        </div>
        <div class="billodetail__data__trip">
          <h2>Cargo</h2>
          <form action="" class="billodetail__data__trip__form" @submit.prevent="">
            <div class="billodetail__data__trip__form__column" v-if="order.ritheen && order.ritheen.id">
              <h3>{{ $t('input.departure') }}</h3><span
                :class="{'label--error':!cargoValidH}">({{ this.cargoH }}/16)</span>
              <label>Pax</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.ritheen.aantalpersonen"
                     @change="copyOrder.ritheen.aantalpersonen = Math.max(Math.min(Math.round(copyOrder.ritheen.aantalpersonen), 8), 1)">
              <label>{{ $t('input.suitcases') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.koffers"
                     @change="copyOrder.passagierheen.koffers = Math.max(Math.min(Math.round(copyOrder.passagierheen.koffers), 8), 0)">
              <label>{{ $t('input.childseat') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.kinderstoel"
                     @change="copyOrder.passagierheen.kinderstoel = Math.max(Math.min(Math.round(copyOrder.passagierheen.kinderstoel), 2), 0)">
              <label>{{ $t('input.strollers') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.buggy"
                     @change="copyOrder.passagierheen.buggy = Math.max(Math.min(Math.round(copyOrder.passagierheen.buggy), 8), 0)">
              <label>{{ $t('input.skis') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.ski"
                     @change="copyOrder.passagierheen.ski = Math.max(Math.min(Math.round(copyOrder.passagierheen.ski), 8), 0)">
              <label>{{ $t('input.golfbags') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.golfbag"
                     @change="copyOrder.passagierheen.golfbag = Math.max(Math.min(Math.round(copyOrder.passagierheen.golfbag), 8), 0)">
              <label>{{ $t('input.bike') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.fietskoffer"
                     @change="copyOrder.passagierheen.fietskoffer = Math.max(Math.min(Math.round(copyOrder.passagierheen.fietskoffer), 3), 0)">
              <label>{{ $t('input.wheelchairs') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.rolstoel"
                     @change="copyOrder.passagierheen.rolstoel = Math.max(Math.min(Math.round(copyOrder.passagierheen.rolstoel), 3), 0)">
              <label>{{ $t('input.rollators') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.rollator"
                     @change="copyOrder.passagierheen.rollator = Math.max(Math.min(Math.round(copyOrder.passagierheen.rollator), 3), 0)">
              <label>{{ $t('input.animals') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.huisdier"
                     @change="copyOrder.passagierheen.huisdier = Math.max(Math.min(Math.round(copyOrder.passagierheen.huisdier), 3), 0)">
            </div>
            <div class="billodetail__data__trip__form__column" v-else>
              <h3>{{ $t('input.departure') }}</h3>
              <p class="wide">No booking</p>
            </div>
            <div class="billodetail__data__trip__form__column" v-if="order.ritterug && order.ritterug.id">
              <h3>{{ $t('back') }}</h3><span :class="{'label--error':!cargoValidT}">({{ this.cargoR }}/16)</span>
              <label>Pax</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.ritterug.aantalpersonen"
                     @change="copyOrder.ritterug.aantalpersonen = Math.max(Math.min(Math.round(copyOrder.ritterug.aantalpersonen), 8), 1)">
              <label>{{ $t('input.suitcases') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.koffers"
                     @change="copyOrder.passagierterug.koffers = Math.max(Math.min(Math.round(copyOrder.passagierterug.koffers), 8), 0)">
              <label>{{ $t('input.childseat') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.kinderstoel"
                     @change="copyOrder.passagierterug.kinderstoel = Math.max(Math.min(Math.round(copyOrder.passagierterug.kinderstoel), 2), 0)">
              <label>{{ $t('input.strollers') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.buggy"
                     @change="copyOrder.passagierterug.buggy = Math.max(Math.min(Math.round(copyOrder.passagierterug.buggy), 8), 0)">
              <label>{{ $t('input.skis') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.ski"
                     @change="copyOrder.passagierterug.ski = Math.max(Math.min(Math.round(copyOrder.passagierterug.ski), 8), 0)">
              <label>{{ $t('input.golfbags') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.golfbag"
                     @change="copyOrder.passagierterug.golfbag = Math.max(Math.min(Math.round(copyOrder.passagierterug.golfbag), 8), 0)">
              <label>{{ $t('input.bike') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.fietskoffer"
                     @change="copyOrder.passagierterug.fietskoffer = Math.max(Math.min(Math.round(copyOrder.passagierterug.fietskoffer), 3), 0)">
              <label>{{ $t('input.wheelchairs') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.rolstoel"
                     @change="copyOrder.passagierterug.rolstoel = Math.max(Math.min(Math.round(copyOrder.passagierterug.rolstoel), 3), 0)">
              <label>{{ $t('input.rollators') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.rollator"
                     @change="copyOrder.passagierterug.rollator = Math.max(Math.min(Math.round(copyOrder.passagierterug.rollator), 3), 0)">
              <label>{{ $t('input.animals') }}</label>
              <input type="number" class="input input--bordered input--nr"
                     :readonly="order.readonlyterug"
                     v-model.number="copyOrder.passagierterug.huisdier"
                     @change="copyOrder.passagierterug.huisdier = Math.max(Math.min(Math.round(copyOrder.passagierterug.huisdier), 3), 0)">
            </div>
            <div class="billodetail__data__trip__form__column" v-else>
              <h3>{{ $t('back') }}</h3>
              <p class="wide">No booking</p>
            </div>
          </form>
        </div>
        <div class="billodetail__data__trip" v-if="order.ritheen && order.ritheen.id">
          <h2>{{ $t('input.departure') }} <span class="chauffeur"
                                                v-if="order.ritheen.tempchauffeur">(Chauffeur: {{
              order.ritheen.tempchauffeur
            }})</span>
          </h2>
          <form action="" @submit.prevent="" class="billodetail__data__trip__form">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.date') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritheen.datum">
              <label>Pickup</label>
              <input type="text" class="input input--bordered" readonly :value="order.ritheen.tijd">
              <p class="wide" v-if="order.ritheen.tijd ==='00:00'">{{ $t('data.timing') }}</p>
              <label>{{ $t('input.airport') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritheen.naarnaam">
              <label>{{ $t('input.flight') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.passagierheen.vluchtnr">
              <label>{{ $t('data.early') }}</label>
              <input type="number" step="0.5" class="input input--bordered input--nr"
                     :readonly="order.readonlyheen"
                     v-model.number="copyOrder.passagierheen.opluchthaven">
              <label for="viphotel">{{ $t('input.hotel') }}</label>
              <input id="viphotel" type="checkbox"
                     :disabled="order.readonlyheen"
                     v-model="copyOrder.ritheen.pohotel">
              <h3>{{ $t('vip.pickup') }}</h3><span></span>
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered"
                     :readonly="order.readonlyheen"
                     v-model="copyOrder.ritheen.vannaam">
              <label>{{ $t('input.street') }} + {{ $t('input.nr') }}</label>
              <div>
                <input type="text" class="input input--bordered"
                       :readonly="order.readonlyheen"
                       v-model="copyOrder.ritheen.vanstraat">
                <input type="text" class="input input--bordered number"
                       :readonly="order.readonlyheen"
                       v-model="copyOrder.ritheen.vannummer">
              </div>
              <label>{{ $t('input.zip') }}</label>
              <input type="text" class="input input--bordered"
                     :readonly="order.readonlyheen"
                     v-model="copyOrder.ritheen.vanpostnr">
              <label>{{ $t('input.city') }}</label>
              <input type="text" class="input input--bordered"
                     :readonly="order.readonlyheen"
                     v-model="copyOrder.ritheen.vanstad">
              <label>{{ $t('input.country') }}</label>
              <select class="select"
                      :disabled="order.readonlyheen"
                      v-model="copyOrder.ritheen.vanland">
                <option value="BE">{{ $t('input.countries.be') }}</option>
                <option value="NL">{{ $t('input.countries.nl') }}</option>
                <option value="FR">{{ $t('input.countries.fr') }}</option>
                <option value="DE">{{ $t('input.countries.de') }}</option>
                <option value="LU">{{ $t('input.countries.lu') }}</option>
              </select>
              <p class="error" v-if="copyOrder.ritheen.pohotel">{{ $t('vip.contacthes') }}</p>
              <p class="error" v-if="copyOrder.ritheen.vanland && copyOrder.ritheen.vanland !== 'BE'">
                {{ $t('vip.contacthesprice') }}</p>
              <label>{{ $t('data.remarks') }}</label>
              <textarea class="textarea" v-model="copyOrder.passagierheen.opmerking"></textarea>
            </div>
          </form>
        </div>
        <div class="billodetail__data__trip" v-else>
          <h2>{{ $t('input.departure') }}</h2>
          <p>No booking</p>
        </div>
        <div class="billodetail__data__trip" v-if="order.ritterug && order.ritterug.id">
          <h2>{{ $t('back') }} <span class="chauffeur"
                                     v-if="order.ritterug.tempchauffeur">(Chauffeur: {{
              order.ritterug.tempchauffeur
            }})</span>
          </h2>
          <form action="" @submit.prevent="" class="billodetail__data__trip__form">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.date') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritterug.datum">
              <label>Pickup</label>
              <input type="text" class="input input--bordered" readonly :value="order.ritterug.tijd">
              <p class="wide" v-if="order.ritterug.tijd ==='00:00'">{{ $t('data.timing') }}</p>
              <label>{{ $t('input.airport') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.ritterug.vannaam">
              <label>{{ $t('input.flight') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="order.passagierterug.vluchtnr">
              <label>Hotel</label>
              <span>/</span>
              <h3>{{ $t('vip.dropoff') }}</h3><span></span>
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered"
                     :readonly="order.readonlyterug"
                     v-model="copyOrder.ritterug.naarnaam">
              <label>{{ $t('input.street') }} + {{ $t('input.nr') }}</label>
              <div>
                <input type="text" class="input input--bordered"
                       :readonly="order.readonlyterug"
                       v-model="copyOrder.ritterug.naarstraat">
                <input type="text" class="input input--bordered number"
                       :readonly="order.readonlyterug"
                       v-model="copyOrder.ritterug.naarnummer">
              </div>
              <label>{{ $t('input.zip') }}</label>
              <input type="text" class="input input--bordered"
                     :readonly="order.readonlyterug"
                     v-model="copyOrder.ritterug.naarpostnr">
              <label>{{ $t('input.city') }}</label>
              <input type="text" class="input input--bordered"
                     :readonly="order.readonlyterug"
                     v-model="copyOrder.ritterug.naarstad">
              <label>{{ $t('input.country') }}</label>
              <select class="select"
                      :disabled="order.readonlyterug"
                      v-model="copyOrder.ritterug.naarland">
                <option value="BE">{{ $t('input.countries.be') }}</option>
                <option value="NL">{{ $t('input.countries.nl') }}</option>
                <option value="FR">{{ $t('input.countries.fr') }}</option>
                <option value="DE">{{ $t('input.countries.de') }}</option>
                <option value="LU">{{ $t('input.countries.lu') }}</option>
              </select>
              <p class="error" v-if="copyOrder.ritterug.naarland && copyOrder.ritterug.naarland !== 'BE'">
                {{ $t('vip.contacthesprice') }}</p>
              <label>{{ $t('data.remarks') }}</label>
              <textarea class="textarea" v-model="copyOrder.passagierterug.opmerking"></textarea>
            </div>
          </form>
        </div>
        <div class="billodetail__data__trip" v-else>
          <h2>{{ $t('back') }}</h2>
          <p>No booking</p>
        </div>
      </div>
    </div>
    <div class="modal" v-if="isHistoriek">
      <div class="modal__wrapper">
        <div class="modal__close" @click="isHistoriek=false">
          <fa-icon icon="times"></fa-icon>
        </div>
        <h1>{{ $t('data.history') }}</h1>
        <div class="modal__body">
          <template v-if="order.ritheen && order.ritheen.id && history.heen">
            <h2>{{ $t('input.departure') }}</h2>
            <div class="billodetail__data__trip__history">
              <table class="table">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column">{{ $t('data.date') }}</th>
                  <th class="table__header__column">{{ $t('data.description') }}</th>
                  <th class="table__header__column">{{ $t('input.name') }}</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" v-for="(item,index) in history.heen" :key="`HH-${index}`">
                  <td class="table__body__item__column">{{ $d(new Date(item.CREATIONDATE), 'hes') }}</td>
                  <td class="table__body__item__column" v-html="item.VERSLAG"></td>
                  <td class="table__body__item__column">{{ item.NAAM }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-if="order.ritterug && order.ritterug.id && history.terug">
            <h2>{{ $t('back') }}</h2>
            <div class="billodetail__data__trip__history">
              <table class="table">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column">{{ $t('data.date') }}</th>
                  <th class="table__header__column">{{ $t('data.description') }}</th>
                  <th class="table__header__column">{{ $t('input.name') }}</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" v-for="(item,index) in history.terug" :key="`HH-${index}`">
                  <td class="table__body__item__column">{{ $d(new Date(item.CREATIONDATE), 'hes') }}</td>
                  <td class="table__body__item__column" v-html="item.VERSLAG"></td>
                  <td class="table__body__item__column">{{ item.NAAM }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PegaseDetail",

  data() {
    return {
      order: null,
      copyOrder: null,
      history: {
        heen: [],
        terug: []
      },
      isSending: false,
      isSent: false,
      sentTimeout: null,
      isHistoriek: false,

      isSaving: false
    };
  },

  created() {
    if (!this.hasPegase) {
      this.$router.replace('/');
      return;
    }
    this.initDetail();
  },

  beforeDestroy() {
    clearTimeout(this.sentTimeout);
  },

  computed: {
    cargoH() {
      if (!this.copyOrder || !(this.order.ritheen && this.order.ritheen.id)) {
        return false;
      }
      const p = this.copyOrder.passagierheen;
      return parseInt(this.copyOrder.ritheen.aantalpersonen)
          + parseInt(p.koffers)
          + parseInt(p.rolstoel) * 5
          + parseInt(p.ski) * 2
          + parseInt(p.golfbag) * 2
          + parseInt(p.rollator) * 5
          + parseInt(p.fietskoffer) * 5
          + parseInt(p.buggy) * 2
          + parseInt(p.huisdier) * 5;
    },
    cargoR() {
      if (!this.copyOrder || !(this.order.ritterug && this.order.ritterug.id)) {
        return false;
      }
      const p = this.copyOrder.passagierterug;
      return parseInt(this.copyOrder.ritterug.aantalpersonen)
          + parseInt(p.koffers)
          + parseInt(p.rolstoel) * 5
          + parseInt(p.ski) * 2
          + parseInt(p.golfbag) * 2
          + parseInt(p.rollator) * 5
          + parseInt(p.fietskoffer) * 5
          + parseInt(p.buggy) * 2
          + parseInt(p.huisdier) * 5;
    },
    cargoValidH() {
      if (!this.copyOrder) {
        return false;
      }
      if (!(this.order.ritheen && this.order.ritheen.id)) {
        return true;
      }
      return this.cargoH <= 16 && this.copyOrder.ritheen.aantalpersonen > 0;
    },
    cargoValidT() {
      if (!this.copyOrder) {
        return false;
      }
      if (!(this.order.ritterug && this.order.ritterug.id)) {
        return true;
      }
      return this.cargoR <= 16 && this.copyOrder.ritterug.aantalpersonen > 0;
    },

    hasPegase() {
      return this.$store.getters['user/hasPegase'];
    },

    bonnr() {
      return this.$route.params.bonnr;
    }
  },

  methods: {
    async initDetail() {
      try {
        await this.$store.dispatch('user/checkUserAccess', {bonnr: this.bonnr});
        await this.fetchData();
      } catch (e) {
        alert(e);
        await this.$router.replace('/pegase');
      }
    },
    async fetchData() {
      try {
        this.order = await this.$store.dispatch('pegase/getOrderDetail', {bonnr: this.bonnr});
        this.copyOrder = JSON.parse(JSON.stringify(this.order));
        this.fetchHistory(this.order.ritheen?.id, this.order.ritterug?.id);
      } catch (e) {
        alert(e);
      }
    },

    async fetchHistory(heen, terug) {
      try {
        if (heen) {
          this.history.heen = await this.$store.dispatch('pegase/getOrderHistory', heen);
        }
        if (terug) {
          this.history.terug = await this.$store.dispatch('pegase/getOrderHistory', terug);
        }
      } catch (e) {
        alert(e);
      }
    },

    resetEdits() {
      this.copyOrder = JSON.parse(JSON.stringify(this.order));
    },

    async print() {
      try {
        const pas = this.order.passagierheen ? this.order.passagierheen : this.order.passagierterug;
        const res = await this.$store.dispatch('common/preview', {
          id: this.bonnr,
          template: "pegaseconfirmation",
          taal: pas.lang,
          pdf: true
        });
        if (res.filename) {
          window.open(`${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/${res.filename}`);
        }
      } catch (e) {
        alert(e);
      }
    },

    toonRit(id) {
      const link = process.env.VUE_APP_ROOT_API + process.env.VUE_APP_ROOT_API_ENV + "/templates/billorit.php?id=" + id + "&taal=" + "NL" + "&access_token=" + this.$store.getters.token;
      window.open(link);
    },

    async sendMail(type) {
      this.isSending = true;
      const pas = this.order.passagierheen ? this.order.passagierheen : this.order.passagierterug;
      const dir = type === 'T' ? 'terug' : 'heen';
      const id = type === 'confirm' ? this.bonnr : this.order['rit' + dir].id;
      const template = type === 'confirm' ? 'pegaseconfirmation' : 'pegasepickup';

      try {
        await this.$store.dispatch('pegase/sendMail', {
          id,
          lang: pas.lang.toUpperCase(),
          template,
          profile: 'HES',
          emailto: pas.custemail
        });
        this.isSent = type;
        this.sentTimeout = setTimeout(() => {
          this.isSent = false;
        }, 500);
      } catch (e) {
        alert(e);
      }
      this.isSending = false;
    },

    handleNumberInput(event, min, max) {
      let value = event.target.value;
      if (!parseInt(value)) {
        event.target.value = min;
      } else {
        if (parseInt(value) > max) {
          event.target.value = max;
        }
        if (parseInt(value) < min) {
          event.target.value = min;
        }
      }
    },

    async saveOrder() {
      this.isSaving = true;
      const ritheen = this.order.ritheen;
      const ritterug = this.order.ritterug;
      const data = {
        bonnr: this.bonnr,
        po: ritheen.id ? this.order.passagierheen.po : this.order.passagierterug.po,
        talic: ritheen.id ? this.order.passagierheen.talic : this.order.passagierterug.talic,
        taemail: ritheen.id ? this.order.passagierheen.taemail : this.order.passagierterug.taemail,
        passagier: ritheen.id ? this.copyOrder.passagierheen.passagier : this.copyOrder.passagierterug.passagier,
        tel: ritheen.id ? this.copyOrder.passagierheen.tel : this.copyOrder.passagierterug.tel,
        custemail: ritheen.id ? this.copyOrder.passagierheen.custemail : this.copyOrder.passagierterug.custemail,
        lang: ritheen.id ? this.copyOrder.passagierheen.lang.toUpperCase() : this.copyOrder.passagierterug.lang.toUpperCase(),
        readonlyheen: this.order.readonlyheen,
        readonlyterug: this.order.readonlyterug
      };
      if (ritheen.id) {
        data.idritheen = this.order.ritheen.id;
        data.heenhotel = parseInt(this.copyOrder.ritheen.pohotel);
        data.vannaamheen = this.copyOrder.ritheen.vannaam;
        data.vanstraatheen = this.copyOrder.ritheen.vanstraat;
        data.vannummerheen = this.copyOrder.ritheen.vannummer;
        data.vanpostnrheen = this.copyOrder.ritheen.vanpostnr;
        data.vanstadheen = this.copyOrder.ritheen.vanstad;
        data.vanlandheen = this.copyOrder.ritheen.vanland;
        data.paxheen = this.copyOrder.ritheen.aantalpersonen;
        data.koffersheen = this.copyOrder.passagierheen.koffers;
        data.kinderstoelenheen = this.copyOrder.passagierheen.kinderstoel;
        data.kinderwagensheen = this.copyOrder.passagierheen.buggy;
        data.skisheen = this.copyOrder.passagierheen.ski;
        data.golfbagsheen = this.copyOrder.passagierheen.golfbag;
        data.rolstoelenheen = this.copyOrder.passagierheen.rolstoel;
        data.rollatorsheen = this.copyOrder.passagierheen.rollator;
        data.fietskoffersheen = this.copyOrder.passagierheen.fietskoffer;
        data.huisdierenheen = this.copyOrder.passagierheen.huisdier;
        data.cargoheen = this.cargoH;
        data.opmerkingheen = this.copyOrder.passagierheen.opmerking;
        data.opluchthavenheen = this.copyOrder.passagierheen.opluchthaven;
      }
      if (ritterug.id) {
        data.idritterug = this.order.ritterug.id;
        data.terughotel = "0";
        data.naarnaamterug = this.copyOrder.ritterug.naarnaam;
        data.naarstraatterug = this.copyOrder.ritterug.naarstraat;
        data.naarnummerterug = this.copyOrder.ritterug.naarnummer;
        data.naarpostnrterug = this.copyOrder.ritterug.naarpostnr;
        data.naarstadterug = this.copyOrder.ritterug.naarstad;
        data.naarlandterug = this.copyOrder.ritterug.naarland;
        data.paxterug = this.copyOrder.ritterug.aantalpersonen;
        data.koffersterug = this.copyOrder.passagierterug.koffers;
        data.kinderstoelenterug = this.copyOrder.passagierterug.kinderstoel;
        data.kinderwagensterug = this.copyOrder.passagierterug.buggy;
        data.skisterug = this.copyOrder.passagierterug.ski;
        data.golfbagsterug = this.copyOrder.passagierterug.golfbag;
        data.rolstoelenterug = this.copyOrder.passagierterug.rolstoel;
        data.rollatorsterug = this.copyOrder.passagierterug.rollator;
        data.fietskoffersterug = this.copyOrder.passagierterug.fietskoffer;
        data.huisdierenterug = this.copyOrder.passagierterug.huisdier;
        data.cargoterug = this.cargoR;
        data.opmerkingterug = this.copyOrder.passagierterug.opmerking;
        data.opluchthaventerug = this.copyOrder.passagierterug.opluchthaven;
      }
      try {
        if (ritheen.id) {
          await this.$store.dispatch('common/checkAddress', {
            street: this.copyOrder.ritheen.vanstraat,
            nr: this.copyOrder.ritheen.vannummer,
            postal: this.copyOrder.ritheen.vanpostnr,
            city: this.copyOrder.ritheen.vanstad,
            country: this.copyOrder.ritheen.vanland
          });
        }
        if (ritterug.id) {
          await this.$store.dispatch('common/checkAddress', {
            street: this.copyOrder.ritterug.naarstraat,
            nr: this.copyOrder.ritterug.naarnummer,
            postal: this.copyOrder.ritterug.naarpostnr,
            city: this.copyOrder.ritterug.naarstad,
            country: this.copyOrder.ritterug.naarland
          });
        }
        await this.$store.dispatch('pegase/saveOrder', data);
        this.fetchData();
      } catch (e) {
        alert(e);
      }
      this.isSaving = false;
    }
  }
}
</script>