export default{
    commissions(state){
        return state.commissions;
    },

    openCommissions(state){
        return state.commissions?state.commissions.open:[];
    },

    unpaidCommissions(state){
        return state.commissions?state.commissions.unpaid.sort((a,b)=> a.ID - b.ID):[];
    },

    paidCommissions(state){
        return state.commissions?state.commissions.paid.sort((a,b)=> b.ID - a.ID):[];
    }
}