export default {
    talicData(state){
        return state.talicData;
    },
    subAccounts(state){
        return state.subAccounts;
    },
    hasSubAccounts(state){
        return state.subAccounts && state.subAccounts.length;
    },
    activeSub(state){
        return state.activeSub;
    },
    vouchers(state){
        return state.vouchers;
    },
    hasVouchers(state){
        return state.vouchers && state.vouchers.length;
    },


    hasVip(state){
        if(!state.talicData){
            return false;
        }
        const center = state.talicData.CENTER;
        return center === 0 || center === 1 || center === 2 || center === 3;
    },

    hasPegase(state){
        if(!state.talicData){
            return false;
        }
        return state.talicData.CENTER === 50079;
    },

    hasFree(state){
        if(!state.talicData){
            return false;
        }
        return state.talicData.CENTER === 1;
    },

    hasGroups(state){
        if(!state.talicData){
            return false;
        }
        const talic = state.talicData.TALIC;
        return talic === 118603 || talic === 118517;
    },

    hasCommissions(state) {
        if(!state.talicData){
            return false;
        }
        const center = state.talicData.CENTER;
        return center === 0 || center === 500 || center === 600 || center === 999;
    },

    hasManagement(state){
        if(!state.talicData){
            return false;
        }
        return !(state.subAccounts && state.subAccounts.length) || (state.activeSub && state.activeSub.ismanager);
    }
}