<template>
    <div class="modal modal--payment">
        <div class="modal__wrapper">
            <div class="modal__close" @click="$emit('on-close')">
                <fa-icon icon="times"></fa-icon>
            </div>
            <h1>{{ $t('commissions.paynota') }}</h1>
            <div class="modal__body">
                <template v-if="isPaid">
                    <p>{{ $t('commissions.paysuccess') }}</p>
                </template>
                <form v-else action="">
                    <div class="modal__body__block">
                        <p><span>{{ $t('commissions.orderid') }}</span><b>{{data.id}}</b></p>
                        <p><span>{{ $t('commissions.description') }}</span><b>{{data.description}}</b></p>
                        <p><span>{{ $t('commissions.fee') }}</span><b>€{{data.amount}}</b></p>
                        <p><span>{{ $t('commissions.name') }}</span><b>{{data.name}}</b></p>
                        <p><span>{{ $t('commissions.email') }}</span><b>{{data.email}}</b></p>
                        <p>
                            <label for="CardType">{{ $t('commissions.paymethod') }}</label>
                            <select class="select" name="CardType" id="CardType" v-model="cardtype">
                                <option value="AmEx">American Express</option>
                                <option value="MasterCard">MasterCard</option>
                                <option value="Visa" selected>VISA</option>
                            </select>
                        </p>
                        <p>
                            <label for="cardname">{{ $t('commissions.cardholder') }}</label>
                            <input id="cardname" v-model="cardname" type="text" class="input input--bordered" autocomplete="off">
                        </p>
                        <p>
                            <label for="cardnr">{{ $t('commissions.cardnr') }}</label>
                            <input id="cardnr" v-model="cardnr" type="text" class="input input--bordered" autocomplete="off">
                        </p>
                        <p>
                            <label for="cvv">{{ $t('commissions.cvv') }}</label>
                            <input id="cvv" v-model="cvs" type="text" class="input input--bordered" autocomplete="off">
                        </p>
                        <p>
                            <label for="CardMonth">{{ $t('commissions.carddate') }}</label>
                            <select class="select" name="CardMonth" id="CardMonth" v-model="cardMonth">
                                <option v-for="index in 12" :value="index" :key="'month'+index">{{index}}</option>
                            </select>
                            /
                            <select class="select" name="CardYear" id="CardYear" v-model="cardYear">
                                <option v-for="index in 10" :value="currentDate.getFullYear() - 1 + index" :key="'year'+index">{{currentDate.getFullYear() - 1 + index}}</option>
                            </select>
                        </p>
                    </div>
                </form>
            </div>
            <div class="modal__controls" v-if="!isPaid">
                <div class="button button--accept" :class="{'button--disabled':isCardInValid}" @click="submitPayment">{{ $t('commissions.pay') }}</div>
                <p v-if="isCardInValid" class="error">{{$t(isCardInValid)}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {CreditCardMixin} from "@/mixins/CreditCardMixin";
    import PaymentService from "@/services/payment";

    export default {
        name: "PayModal",

        props: {
            data: {required: true}
        },

        mixins:[CreditCardMixin],

        data() {
            return {
                currentDate: new Date(),

                cardtype: 'Visa',
                cardnr: '',
                cardname:'',
                cvs: '',
                cardMonth: null,
                cardYear: null,
                naam: this.data.name,
                wt: this.data.amountwt || 0,
                description: this.data.description || "",
                email: this.data.email,

                //isCardInValid: false,
                isPaid: false,
            };
        },

        computed: {
          isCardInValid(){
            return CreditCardMixin.checkValid(this.cardnr,this.cardtype, this.cvs).error;
          }
        },

        methods: {
            async submitPayment(){
                this.isCardInValid = CreditCardMixin.checkValid(this.cardnr,this.cardtype, this.cvs).error;
                const dateInValid = this.cardMonth < this.currentDate.getMonth() + 1 && this.cardYear === this.currentDate.getFullYear() || this.cardYear < this.currentDate.getFullYear();
                if (dateInValid){
                    this.isCardInValid = "commissions.expired";
                }
                if (!this.cardname || this.cardname.length===0){
                    this.isCardInValid = "commissions.nocardholder"
                }
                if(!this.isCardInValid) {
                    const data = {
                        ALIAS: "",
                        ACCOUNT: process.env.VUE_APP_PAYMENT_ACCOUNT,
                        BACKURL: "",
                        TARGET: this.data.target,
                        CardType: this.cardtype,
                        CARDNR: this.cardnr.toString().replace(/\s/g, ""),
                        CVS: this.cvs.toString(),
                        NAAM: this.cardname,
                        AMOUNT: this.data.amount.toString(),
                        AMOUNTWT: this.wt.toString(),
                        ORDERID: this.data.id.toString(),
                        MAAND: this.cardMonth.toString(),
                        JAAR: this.cardYear.toString(),
                        COM: this.data.description,
                        browserColorDepth: screen.colorDepth.toString(),
                        browserJavaEnabled: navigator.javaEnabled().toString(),
                        browserLanguage: navigator.language,
                        browserScreenHeight: screen.height.toString(),
                        browserScreenWidth: screen.width.toString(),
                        browserTimeZone: new Date().getTimezoneOffset().toString()
                    }
                    try{
                      await PaymentService.pay(data,this.data.type);
                      this.isPaid = true;
                      this.$emit('payment-complete');
                    } catch(e){
                      alert(e);
                    }
                }
            }
        }
    }
</script>