export default {
    async getOrder(context, po) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoprice/leesgroupdata?po=${po}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch billo groups order data, invalid po.'
            );
            throw error;
        }
        return responseData.data;
    },

    async checkAddress(context, {street, zip, city, country}) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/locations/geocode/${street} ${zip} ${city} ${country}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!street || !zip || !city || !response.ok || !responseData.status || !responseData.data.FORMATTED_ADDRESS) {
            const error = new Error(
                responseData.error_description || `Failed to validate address, ${street} ${zip} ${city} ${country}`
            );
            throw error;
        }
    },

    async saveOrder(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoprice/newgrouporder?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to save billo groups order data.'
            );
            throw error;
        }
    }
}