import Vue from 'vue'
import VueRouter from 'vue-router'

//token
import {store} from "@/store";
//pages
import Login from "../views/Login";
import Messages from "@/views/Messages";
import Management from "@/views/Management";
import Free from "@/views/Free";
import Commissions from "@/views/Commissions";
import Billo from "@/views/Billo";
import BilloDetail from "@/views/BilloDetail";
import Vip from "@/views/Vip";
import VipDetail from "@/views/VipDetail";
import Pegase from "@/views/Pegase";
import PegaseDetail from "@/views/PegaseDetail";
import Groups from "@/views/Groups";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/messages',
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/management',
    name: 'Management',
    component: Management,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/free',
    name: 'Free',
    component: Free,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/commissions',
    name: 'Commissions',
    component: Commissions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/billo',
    name: 'Billo',
    component: Billo,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/billo/:bonnr',
    name: 'BilloDetail',
    component: BilloDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path:'/groups',
    name: 'BilloGroups',
    component: Groups,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vip',
    name: 'Vip',
    component: Vip,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vip/:po',
    name: 'VipDetail',
    component: VipDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pegase',
    name: 'Pegase',
    component: Pegase,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pegase/:bonnr',
    name: 'PegaseDetail',
    component: PegaseDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {requiresUnauth: true}
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next({
      path: '/login',
      query:{
        nextUrl: to.fullPath
      }
    });
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
