export default{
    async getOrders(context, {PageNumber,PageSize,query}){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/listBilloOrders?access_token=${token}`;
        const data = {
            talic,
            PageNumber,
            PageSize,
            ID: query.ID || "",
            RITID: query.RITID || "",
            BONNR: query.BONNR || "",
            PO: query.PO || "",
            DATUM: query.DATUM || "",
            EMAIL: query.EMAIL || "",
            TEL: query.TEL || "",
            NAAM: query.NAAM || "",
            STRAAT: query.STRAAT || "",
            POSTNR: query.POSTNR || "",
            STAD: query.STAD || "",
            HT: query.HT || "",
            ORDERBY: query.ORDERBY || "",
            SORTORDER: query.SORTORDER || ""
        }
        const response = await fetch(url,{
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch billo orders.'
            );
            throw error;
        }
        context.commit('setOrders', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / PageSize));
    },

    clearOrders(context){
        context.commit('setOrders', []);
        context.commit('setPages', 0);
    },

    async getOrderDetail(context, {bonnr}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk?bonnr=${bonnr}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch billo order detail.'
            );
            throw error;
        }
        return responseData;
    },

    async getOrderHistory(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/leeswijzigingen/${id}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch billo order history.'
            );
            throw error;
        }
        return responseData.data;
    },

    async sendMail(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/sendmail/Sendemailextended?access_token=${token}`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch send billo order email.'
            );
            throw error;
        }
    },

    async saveOrder(context, payload){
        const token = context.rootGetters.token;

        const sub = context.rootGetters['user/activeSub'];
        const talicData = context.rootGetters['user/talicData'];
        if(sub){
            payload.username = `${sub.talic} ${talicData.TANAAM} ${sub.voornaam} ${sub.naam}`;
            payload.userid = sub.uid;
        }else{
            payload.username = `${talicData.TALIC} ${talicData.TANAAM}`;
            payload.userid = talicData.TALIC;
        }

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/savebooking?access_token=${token}`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to save billo order edits.'
            );
            throw error;
        }
    },


}