<template>
  <div class="page billo">
    <h1>{{ $t('home.menu.overview') }}</h1>
    <div class="page__content">
      <div class="billo__filter filter">
        <div>
          <label for="sbb">{{ $t('data.ordernr') }}</label>
          <input id="sbb" type="text" class="input input--bordered"
                 v-model="searchBillo.BONNR"
                 @keypress.enter="searchClicked">
        </div>
        <div>
          <label>{{ $t('data.date') }}</label>
          <date-picker id="datum" v-model="searchBillo.DATUM" type="date" format="DD/MM/YYYY"
                       value-type="YYYY/MM/DD"></date-picker>
        </div>
        <div>
          <label for="sbn">{{ $t('input.name') }}</label>
          <input id="sbn" type="text" class="input input--bordered"
                 v-model="searchBillo.NAAM"
                 @keypress.enter="searchClicked">
        </div>
        <div>
          <button class="button" @click="searchClicked">{{ $t('search') }}</button>
          <button class="button button--cancel" @click="resetClicked">{{ $t('reset') }}</button>
        </div>
      </div>
      <div class="billo__data">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">{{ $t('data.ordernr') }}</th>
            <th class="table__header__column">{{ $t('data.type') }}</th>
            <th class="table__header__column">{{ $t('data.date') }}</th>
            <th class="table__header__column">{{ $t('data.time') }}</th>
            <th class="table__header__column">{{ $t('data.customer') }}</th>
            <th class="table__header__column">{{ $t('input.mobile') }}</th>
            <th class="table__header__column">{{ $t('input.flight') }}</th>
            <th class="table__header__column">{{ $t('data.flighttime') }}</th>
            <th class="table__header__column">{{ $t('data.from') }}</th>
            <th class="table__header__column">{{ $t('data.to') }}</th>
            <th class="table__header__column">{{ $t('data.remarks') }}</th>
            <th class="table__header__column">Pax</th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" @click="openBillo(item.BONNR)" v-for="item in orders"
              :key="item.ID">
            <td class="table__body__item__column">{{ item.BONNR }}</td>
            <td class="table__body__item__column">{{ item.SOORT }}</td>
            <td class="table__body__item__column">{{ item.DAT }}</td>
            <td class="table__body__item__column">{{ item.UUR }}</td>
            <td class="table__body__item__column">{{ item.NAAM }}</td>
            <td class="table__body__item__column">{{ item.TEL }}</td>
            <td class="table__body__item__column">{{ item.VLUCHTNR }}</td>
            <td class="table__body__item__column">{{ item.uurvlucht }}</td>
            <td class="table__body__item__column">{{ item.VANNAAM }}<br>{{ item.VANSTRAAT }}
              {{ item.VANNUMMER }}<br>{{ item.VANPOSTNR }} {{ item.VANSTAD }}
            </td>
            <td class="table__body__item__column">{{ item.NAARNAAM }}<br>{{ item.NAARSTRAAT }}
              {{ item.NAARNUMMER }}<br>{{ item.NAARPOSTNR }} {{ item.NAARSTAD }}
            </td>
            <td class="table__body__item__column" v-html="item.OPMERKING"></td>
            <td class="table__body__item__column">{{ item.AANTALREIZIGERS }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="billo__pages" v-if="maxPages > 1">
        <button class="button" v-if="maxPages>1" :class="{'button--disabled':currentPage===1}"
                @click="currentPage--">{{ $t('previous') }}
        </button>
        <button class="button" v-if="maxPages>1" :class="{'button--disabled':currentPage===maxPages}"
                @click="currentPage++">
          {{ $t('next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  name: "Billo",
  components: {
    DatePicker
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 25,
      searchBillo: {
        ID: "",
        RITID: "",
        BONNR: "",
        PO: "",
        DATUM: "",
        EMAIL: "",
        TEL: "",
        NAAM: "",
        STRAAT: "",
        POSTNR: "",
        STAD: "",
        HT: "",
        ORDERBY: "",
        SORTORDER: ""
      }
    };
  },

  created() {
    this.getOrders();
  },

  computed: {
    orders() {
      return this.$store.getters['billo/orders'];
    },
    hasOrders() {
      return this.$store.getters['billo/hasOrders'];
    },
    maxPages() {
      return this.$store.getters['billo/pages'];
    }
  },

  watch: {
    currentPage() {
      this.getOrders();
    }
  },

  methods: {
    searchClicked() {
      this.currentPage = 1;
      this.getOrders();
    },
    resetClicked() {
      this.searchBillo = {
        ID: "",
        RITID: "",
        BONNR: "",
        PO: "",
        DATUM: "",
        EMAIL: "",
        TEL: "",
        NAAM: "",
        STRAAT: "",
        POSTNR: "",
        STAD: "",
        HT: "",
        ORDERBY: "",
        SORTORDER: ""
      }
      this.$store.dispatch('billo/clearOrders');
      this.searchClicked();
    },

    async getOrders() {
      try {
        await this.$store.dispatch('billo/getOrders', {
          PageSize: this.pageSize,
          PageNumber: this.currentPage,
          query: this.searchBillo
        });
      } catch (e) {
        alert(e);
      }
    },
    openBillo(bonnr) {
      this.$router.push(`/billo/${bonnr}`);
    }
  }
}
</script>