<template>
  <div class="page billodetail">
    <h1>{{ $t('home.menu.overview') }} - {{ bonnr }}
      <span class="billodetail__head__controls">
        <button class="back" @click="$router.push('/billo')"
                :title="$t('back')">
                  <fa-icon icon="arrow-left"></fa-icon>
                </button>
      </span>
    </h1>
    <div class="page__content" v-if="copyOrder">
      <div class="billodetail__controls">
        <button class="button" @click="showOrder">{{ $t('actions.showorder') }}</button>
        <button class="button" :class="{'button--disabled':isSending, 'button--accept':isSent==='confirm'}"
                @click="sendMail('confirm')">{{ $t('actions.sendorder') }}
        </button>
      </div>
      <div class="billodetail__data">
        <div class="billodetail__data__trip" v-if="copyOrder.dataheen">
          <h2>{{ $t('input.departure') }}
            <span class="billodetail__data__trip__controls" v-if="editableH">
                <button class="cancel" @click="resetEditPart('dataheen')"
                        :title="`${$t('actions.resetedits')} ${$t('input.departure')}`">
                  <fa-icon icon="history"></fa-icon>
                </button>
                <button class="save" @click="saveEditPart('dataheen')"
                        :title="`${$t('actions.saveedits')} ${$t('input.departure')}`">
                  <fa-icon icon="save"></fa-icon>
                </button>
              </span>
          </h2>
          <form class="billodetail__data__trip__form" action="" @submit.prevent="">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.ordernr') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.BONNR">
              <label>{{ $t('input.po') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.PO">
              <label>{{ $t('input.class') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.SOORT">
              <label>PAX</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.AANTALREIZIGERS">
              <label>{{ $t('data.date') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.DATUM">
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.VANNAAM">
              <label>{{ $t('input.mobile') }}</label>
              <input type="tel" class="input input--bordered" :readonly="!editableH"
                     :class="{'input--edited':copyOrder.dataheen.TEL !== order.dataheen.TEL}"
                     v-model="copyOrder.dataheen.TEL">
              <label>{{ $t('input.email') }}</label>
              <input type="email" class="input input--bordered" :readonly="!editableH"
                     :class="{'input--edited':copyOrder.dataheen.CUSTEMAIL !== order.dataheen.CUSTEMAIL}"
                     v-model="copyOrder.dataheen.CUSTEMAIL">
            </div>
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.from') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.VANNAAM">
              <label></label>
              <div>
                <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.VANSTRAAT">
                <input type="text" class="input input--bordered number" readonly v-model="copyOrder.dataheen.VANNUMMER">
              </div>
              <span></span>
              <div>
                <input type="text" class="input input--bordered postal" readonly v-model="copyOrder.dataheen.VANPOSTNR">
                <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.VANSTAD">
              </div>
              <label>{{ $t('data.to') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.NAARNAAM">
              <label>{{ $t('input.flight') }}</label>
              <input type="text" class="input input--bordered" :readonly="!editableH"
                     :class="{'input--edited':copyOrder.dataheen.VLUCHTNR !== order.dataheen.VLUCHTNR}"
                     v-model="copyOrder.dataheen.VLUCHTNR">
              <label>{{ $t('data.flighttime') }}</label>
              <date-picker v-model="copyOrder.dataheen.UURVLUCHT" class="time" type="time"
                           format="HH:mm" value-type="HH:mm"
                           :minute-step="5"
                           :class="{'edited':copyOrder.dataheen.UURVLUCHT!==order.dataheen.UURVLUCHT}"
                           :disabled="!editableH"></date-picker>
              <span></span>
              <span class="textarea" v-html="copyOrder.dataheen.OPMERKING"></span>
              <label>{{ $t('input.price') }}</label>
              <input type="text" class="input input--bordered number" readonly v-model="copyOrder.dataheen.OPGAVEPRIJS">
              <label>{{ $t('data.statuspayment') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataheen.STATUSBETALING">
            </div>
          </form>
          <p class="error" v-if="!editableH">{{ $t('billo.noteditable') }}</p>
          <template v-if="order.dataheen && history.dataheen">
            <h3>{{ $t('data.history') }}</h3>
            <div class="billodetail__data__trip__history">
              <table class="table">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column">{{ $t('data.date') }}</th>
                  <th class="table__header__column">{{ $t('data.description') }}</th>
                  <th class="table__header__column">{{ $t('input.name') }}</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" v-for="(item,index) in history.dataheen" :key="`HH-${index}`">
                  <td class="table__body__item__column">{{ $d(new Date(item.CREATIONDATE), 'hes') }}</td>
                  <td class="table__body__item__column" v-html="item.VERSLAG"></td>
                  <td class="table__body__item__column">{{ item.NAAM }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
        <div class="billodetail__data__trip" v-if="copyOrder.dataterug">
          <h2>{{ $t('back') }}
            <span class="billodetail__data__trip__controls" v-if="editableT">
                <button class="cancel" @click="resetEditPart('dataterug')"
                        :title="`${$t('actions.resetedits')} ${$t('input.arrival')}`">
                  <fa-icon icon="history"></fa-icon>
                </button>
                <button class="save" @click="saveEditPart('dataterug')"
                        :title="`${$t('actions.saveedits')} ${$t('input.arrival')}`">
                  <fa-icon icon="save"></fa-icon>
                </button>
              </span>
          </h2>
          <form class="billodetail__data__trip__form" action="" @submit.prevent="">
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.ordernr') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.BONNR">
              <label>{{ $t('input.po') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.PO">
              <label>{{ $t('input.class') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.SOORT">
              <label>PAX</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.AANTALREIZIGERS">
              <label>{{ $t('data.date') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.DATUM">
              <label>{{ $t('input.name') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.VANNAAM">
              <label>{{ $t('input.mobile') }}</label>
              <input type="tel" class="input input--bordered" :readonly="!editableT"
                     :class="{'input--edited':copyOrder.dataterug.TEL !== order.dataterug.TEL}"
                     v-model="copyOrder.dataterug.TEL">
              <label>{{ $t('input.email') }}</label>
              <input type="email" class="input input--bordered" :readonly="!editableT"
                     :class="{'input--edited':copyOrder.dataterug.CUSTEMAIL !== order.dataterug.CUSTEMAIL}"
                     v-model="copyOrder.dataterug.CUSTEMAIL">
            </div>
            <div class="billodetail__data__trip__form__column">
              <label>{{ $t('data.from') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.VANNAAM">
              <label>{{ $t('data.to') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.NAARNAAM">
              <label></label>
              <div>
                <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.NAARSTRAAT">
                <input type="text" class="input input--bordered number" readonly
                       v-model="copyOrder.dataterug.NAARNUMMER">
              </div>
              <span></span>
              <div>
                <input type="text" class="input input--bordered postal" readonly
                       v-model="copyOrder.dataterug.NAARPOSTNR">
                <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.NAARSTAD">
              </div>
              <label>{{ $t('input.flight') }}</label>
              <input type="text" class="input input--bordered" :readonly="!editableT"
                     :class="{'input--edited':copyOrder.dataterug.VLUCHTNR !== order.dataterug.VLUCHTNR}"
                     v-model="copyOrder.dataterug.VLUCHTNR">
              <label>{{ $t('data.flighttime') }}</label>
              <date-picker v-model="copyOrder.dataterug.UURVLUCHT" class="time" type="time"
                           format="HH:mm" value-type="HH:mm"
                           :minute-step="5"
                           :class="{'edited':copyOrder.dataterug.UURVLUCHT!==order.dataterug.UURVLUCHT}"
                           :disabled="!editableT"></date-picker>
              <span></span>
              <span class="textarea" v-html="copyOrder.dataterug.OPMERKING"></span>
              <label>{{ $t('input.price') }}</label>
              <input type="text" class="input input--bordered number" readonly
                     v-model="copyOrder.dataterug.OPGAVEPRIJS">
              <label>{{ $t('data.statuspayment') }}</label>
              <input type="text" class="input input--bordered" readonly v-model="copyOrder.dataterug.STATUSBETALING">
            </div>
          </form>
          <p class="error" v-if="!editableT">{{ $t('billo.noteditable') }}</p>
          <template v-if="order.dataterug && history.dataterug">
            <h3>{{ $t('data.history') }}</h3>
            <div class="billodetail__data__trip__history">
              <table class="table">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column">{{ $t('data.date') }}</th>
                  <th class="table__header__column">{{ $t('data.description') }}</th>
                  <th class="table__header__column">{{ $t('input.name') }}</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" v-for="(item,index) in history.dataterug" :key="`HT-${index}`">
                  <td class="table__body__item__column">{{ $d(new Date(item.CREATIONDATE), 'hes') }}</td>
                  <td class="table__body__item__column" v-html="item.VERSLAG"></td>
                  <td class="table__body__item__column">{{ item.NAAM }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import {ObjectMixin} from "@/mixins/ObjectMixin";

export default {
  name: "BilloDetail",

  components: {
    DatePicker
  },

  data() {
    return {
      order: null,
      copyOrder: null,
      history: {
        dataheen: [],
        dataterug: []
      },

      isSending: false,
      isSent: false,
      sentTimeout: null
    };
  },

  created() {
    this.fetchData();
  },

  beforeDestroy() {
    clearTimeout(this.sentTimeout);
  },

  computed: {
    bonnr() {
      return this.$route.params.bonnr;
    },

    editableH() {
      return this.order.dataheen && parseInt(this.order.dataheen.RITID) === 999999999;
    },

    editableT() {
      return this.order.dataterug && parseInt(this.order.dataterug.RITID) === 999999999;
    }
  },

  methods: {
    async fetchData(side) {
      try {
        this.order = await this.$store.dispatch('billo/getOrderDetail', {bonnr: this.bonnr});
        if (side) {
          this.copyOrder[side] = JSON.parse(JSON.stringify(this.order[side]));
          this.fetchHistory(side);
        } else {
          this.copyOrder = JSON.parse(JSON.stringify(this.order));
          this.fetchHistory('dataheen');
          this.fetchHistory('dataterug');
        }
      } catch (e) {
        alert(e);
      }
    },

    async fetchHistory(side) {
      if (!this.order[side]) {
        return;
      }
      try {
        this.history[side] = await this.$store.dispatch('billo/getOrderHistory', {id: this.order[side].ID});
      } catch (e) {
        alert(e);
      }
    },

    showOrder() {
      const side = this.order.dataheen ? 'dataheen' : 'dataterug';
      const link = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/templates/billoconfirmation.php?id=${this.order[side].BONNR}&taal=${this.order[side].LANG.toUpperCase()}&access_token=${this.$store.getters.token}`;
      const encoded = escape(link);
      const dataLink = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pdf/generate_pdf.json?url=${encoded}`;
      window.open(dataLink);
    },

    async sendMail(type) {
      this.isSending = true;
      let side = 'dataheen';
      if (type === 'T' || !this.order.dataheen) {
        side = 'dataterug';
      }
      const emailto = this.copyOrder.dataheen?this.copyOrder.dataheen.CUSTEMAIL:this.copyOrder.dataterug.CUSTEMAIL;
      const id = type === 'confirm' ? this.order[side].BONNR : this.order[side].RITID;
      const template = type === 'confirm' ? 'billoconfirmation' : 'billopickup';
      try {
        await this.$store.dispatch('billo/sendMail', {
          id,
          lang: this.order[side].LANG.toUpperCase(),
          template,
          profile:'BILLO',
          emailto
        });
        this.isSent = type;
        this.sentTimeout = setTimeout(() => {
          this.isSent = false;
        }, 500);
      } catch (e) {
        alert(e);
      }
      this.isSending = false;
    },

    resetEditPart(side) {
        this.copyOrder[side] = JSON.parse(JSON.stringify(this.order[side]));
    },

    async saveEditPart(side) {
      const edits = ObjectMixin.getObjectDifference(this.order[side], this.copyOrder[side]);
      const changes = ObjectMixin.convertObjectKeyValue(edits, this.order[side]);
      const data = {
        bonnr: this.order[side].BONNR,
        idheen: this.order.dataheen ? this.order.dataheen.ID:null,
        idterug: this.order.dataterug ? this.order.dataterug.ID:null,
        changesheen: side==='dataheen'? changes:[],
        changesterug: side==='dataterug'? changes:[],
      }
      if(!changes.length){
        alert('No changes to save!');
        return;
      }
      try{
        await this.$store.dispatch('billo/saveOrder', data);
        this.fetchData(side);
      } catch(e){
        alert(e);
      }
    }
  }
}
</script>