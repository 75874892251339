<template>
  <div class="login">
    <template v-if="isResetActive">
      <h1 class="title login__title">{{ $t('resetpas.title') }}</h1>
      <template v-if="isResetSuccess">
        <p class="success">{{ $t('resetpas.sent') }}</p>
        <p class="login__forgotten" @click="returnLogin">{{ $t('resetpas.return') }}</p>
      </template>
      <template v-else>
        <template v-if="forgotUser">
          <label for="userresetmail">{{ $t('input.email') }}</label>
          <input class="input login__input" id="userresetmail" type="email"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="resetemail" :placeholder="$t('input.email')" autocomplete="new-password"/>
        </template>
        <template v-else>
          <label for="userreset">{{ $t('login.username') }}</label>
          <input class="input login__input" id="userreset" type="text"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="resetname" :placeholder="$t('login.username')" autocomplete="new-password"/>
        </template>
        <button class="button button--cancel" type="button" @click.prevent="returnLogin">{{ $t('cancel') }}</button>
        <button class="button" type="button"
                @click.prevent="submitReset">{{ $t('resetpas.send') }}
        </button>
        <p v-if="!forgotUser" class="login__forgotten" @click.prevent="forgotUser=true">{{ $t('resetpas.forgotuser') }}</p>
        <div class="error login__error"><span v-if="isResetError">{{ $t('resetpas.error') }}</span>
        </div>
      </template>
    </template>
    <template v-else>
      <h1 class="title login__title">{{ $t('login.title') }}</h1>
      <label for="user">{{ $t('login.username') }}</label>
      <input class="input login__input" id="user" type="text" :name="Math.round(Math.random() * Date.now())"
             v-model="username" :placeholder="$t('login.username')" autocomplete="new-password"/>
      <label for="pass">{{ $t('login.password') }}</label>
      <input class="input login__input" id="pass" type="password"
             :name="Math.round(Math.random() * Date.now())"
             v-model="password" @keypress.enter="submitLogin" :placeholder="$t('login.password')"
             autocomplete="new-password"/>
      <button class="button" :class="{'button--disabled':isLoading||!loginActive}" type="button"
              @click="submitLogin">{{ $t('login.submit') }}
      </button>
      <div class="login__forgotten" @click="isResetActive=true">{{ $t('resetpas.link') }}</div>
      <div class="error login__error"><span v-if="error">{{ $t('login.error') }}</span></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      username: "",
      password: "",
      error: null,

      errorVisible: false,
      errorMessage: "",

      isLoading: false,
      resetname: "",
      resetemail: "",
      isResetActive: false,
      forgotUser: false,
      isResetError: false,
      isResetSuccess: false
    };
  },

  created(){
    this.$store.dispatch('tryLogin');
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/talicData'];
    },
    loginActive() {
      return this.username !== '' && this.password !== '';
    }
  },

  watch: {
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        if(this.$route.query.nextUrl){
          this.$router.replace(this.$route.query.nextUrl);
          return;
        }
        this.$router.replace('/');
      }
    }
  },

  methods: {
    async submitLogin() {
      this.error = null;
      this.isLoading = true;
      const payload = {
        username: this.username,
        password: this.password
      };
      try{
        await this.$store.dispatch('login', payload);
      }
      catch (err){
        this.error = err.message || this.$t('error.login.failed');
      }
      this.isLoading = false;
    },

    async submitReset() {
      if (this.isResetActive) {
        if (this.forgotUser && this.resetemail) {
          try {
            await this.$store.dispatch('user/resetPasswordEmail', this.resetemail);
            this.isResetSuccess = true;
            return;
          } catch (e) {
            this.isResetError = e;
          }
          return;
        }
        if (this.resetname) {
          try {
            await this.$store.dispatch('user/resetPasswordUsername', this.resetname);
            this.isResetSuccess = true;
          } catch (e) {
            this.isResetError = e;
          }
        }
      }
    },

    returnLogin() {
      this.isResetSuccess = false;
      this.isResetError = false;
      this.isResetActive = false;
      this.resetname = "";
      this.resetemail = "";
      this.forgotUser = false;
    }
  }
}
</script>