export default{
    orders(state){
        return state.orders;
    },
    hasOrders(state){
        return state.orders && state.orders.length;
    },
    pages(state){
        return state.pages;
    }
}