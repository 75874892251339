export const CreditCardMixin = {
        _cardErrors: [
            "Unknown card type",
            "No card number provided",
            "Credit card number is in invalid format",
            "Credit card number is invalid",
            "Credit card number has an inappropriate number of digits",
            "Warning! This credit card number is associated with a scam attempt",
            "The card verification value (CVV) is invalid!"
        ],
        _cards: [
            {
                name: "Visa",
                lengths: [13, 16],
                prefixes: [4],
                cvv: 3,
                checkdigit: true
            },
            {
                name: "MasterCard",
                lengths: [16],
                cvv: 3,
                prefixes: [51, 52, 53, 54, 55],
                checkdigit: true
            },
            {
                name: "AmEx",
                lengths: [15],
                cvv: 4,
                prefixes: [34, 37],
                checkdigit: true
            }
        ],

        checkValid: function (nr, type, cvs) {
            const cardType = this._cards.find(c => c.name === type);
            if (!cardType) {
                return {error: this._cardErrors[0]};
            }
            if (!nr && nr.toString().length === 0) {
                return {error: this._cardErrors[1]};
            }

            if (!cvs && cvs.toString().length === 0) {
                return {error: this._cardErrors[6]};
            }
            nr = nr.toString().replace(/\s/g, "");
            cvs = cvs.toString().replace(/\D/g, "");
            const cardnumexp = /^[0-9]{13,19}$/;
            if (!cardnumexp.exec(nr)) {
                return {error: this._cardErrors[2]};
            }
            if (cardType.checkdigit) {
                const checkString = nr;
                let checksum = 0;
                let multiplier = 1;
                let calcChar;
                for (let i = checkString.length - 1; i >= 0; i--) {
                    calcChar = parseInt(checkString[i]) * multiplier;
                    if (calcChar > 9) {
                        checksum++;
                        calcChar -= 10;
                    }
                    checksum += calcChar;
                    multiplier = multiplier === 1 ? 2 : 1;
                }
                if (checksum % 10) {
                    return {error: this._cardErrors[3]};
                }
            }

            if (nr === '5490997771092064') {
                return {error: this._cardErrors[5]};
            }

            let prefixValid = cardType.prefixes.some(p => {
                const exp = new RegExp("^" + p);
                return exp.test(nr);
            });

            let lengthValid = cardType.lengths.some(l => nr.length === l);

            if(cvs.length !== cardType.cvv){
                return {error: this._cardErrors[6]};
            }

            if(!prefixValid){
                return {error: this._cardErrors[3]};
            }
            if(!lengthValid){
                return {error: this._cardErrors[4]};
            }

            return {error:false};
        }
    }
;