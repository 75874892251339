export default {
    setTalicData(state,payload){
        state.talicData = payload;
    },

    setSubAccounts(state,payload){
        state.subAccounts = payload;
    },

    setActiveAccount(state, payload){
        state.activeSub = payload;
    },

    setVouchers(state, payload){
        state.vouchers = payload;
    },

    decreaseIncentive(state, payload){
        if(!state.activeSub){
            return;
        }
        state.activeSub.incentive = parseFloat(state.activeSub.incentive) - parseFloat(payload)
    }
}