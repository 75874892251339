export default{
    async getCommissions(context){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leesoverzicht?talic=${talic}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch commissions.'
            );
            throw error;
        }
        context.commit('setCommissions', responseData.data);
    },

    clearCommissions(context){
        context.commit('setCommissions', []);
    },

    async getCommissionDetail(context, {year,month}){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/detailopencommissie?talic=${talic}&jaar=${year}&maand=${month}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch commission detail.'
            );
            throw error;
        }
        return responseData.data;
    },

    async getCommissionNotaDetail(context, payload){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/detailcommissienota?talic=${talic}&comid=${payload}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch commission nota detail.'
            );
            throw error;
        }
        return responseData.data;
    },

    async createCommissionNota(context, {year, month}){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/maakcommissienota?talic=${talic}&jaar=${year}&maand=${month}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to create commission nota.'
            );
            throw error;
        }
        context.dispatch('getCommissions');
    }
}