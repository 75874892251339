<template>
  <div class="page billo">
    <h1>{{ $t('home.menu.vip') }}</h1>
    <div class="page__content">
      <div class="billo__filter">
        <div>
          <label for="svippo">{{$t('input.po')}}</label>
          <input id="svippo" type="text" class="input input--bordered" v-model="searchVip.PO"
                 @keypress.enter="searchClicked">
        </div>
        <div>
          <button class="button" @click="searchClicked">{{ $t('search') }}</button>
          <button class="button button--cancel" @click="resetClicked">{{ $t('reset') }}</button>
        </div>
      </div>
      <div class="billo__data">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">{{$t('input.po')}}</th>
            <th class="table__header__column">{{$t('input.name')}}</th>
            <th class="table__header__column">Depdate</th>
            <th class="table__header__column">TimeD</th>
            <th class="table__header__column">FlnrD</th>
            <th class="table__header__column">AirpD</th>
            <th class="table__header__column">Retdate</th>
            <th class="table__header__column">TimeR</th>
            <th class="table__header__column">FlnrR</th>
            <th class="table__header__column">AirpR</th>
            <th class="table__header__column">{{ $t('input.lang') }}</th>
            <th class="table__header__column">Ads</th>
            <th class="table__header__column">Chd</th>
            <th class="table__header__column">Inf</th>
            <th class="table__header__column">SSR1</th>
            <th class="table__header__column">QSSR1</th>
            <th class="table__header__column">SSR2</th>
            <th class="table__header__column">QSSR2</th>
            <th class="table__header__column">SSR3</th>
            <th class="table__header__column">QSSR3</th>
            <th class="table__header__column">{{$t('data.changed')}}</th>
            <th class="table__header__column">{{$t('data.status')}}</th>
            <th class="table__header__column">{{$t('data.changes')}}</th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" @click="openVip(item.po)"
              :title="parseInt(item.geweigerd)?$t('vip.refused'):''"
              v-for="item in orders"
              :key="item.ID">
            <td class="table__body__item__column">{{item.po}}</td>
            <td class="table__body__item__column">{{item.leadname}}</td>
            <td class="table__body__item__column">{{item.depdate}}</td>
            <td class="table__body__item__column">{{item.timed}}</td>
            <td class="table__body__item__column">{{item.flnrd}}</td>
            <td class="table__body__item__column">{{item.airpd}}</td>
            <td class="table__body__item__column">{{item.retdate}}</td>
            <td class="table__body__item__column">{{item.timer}}</td>
            <td class="table__body__item__column">{{item.flnrr}}</td>
            <td class="table__body__item__column">{{item.airp}}</td>
            <td class="table__body__item__column">{{item.lang}}</td>
            <td class="table__body__item__column">{{item.ads}}</td>
            <td class="table__body__item__column">{{item.chd}}</td>
            <td class="table__body__item__column">{{item.inf}}</td>
            <td class="table__body__item__column">{{item.ssr1}}</td>
            <td class="table__body__item__column">{{item.qssr1}}</td>
            <td class="table__body__item__column">{{item.ssr2}}</td>
            <td class="table__body__item__column">{{item.qssr2}}</td>
            <td class="table__body__item__column">{{item.ssr3}}</td>
            <td class="table__body__item__column">{{item.qssr3}}</td>
            <td class="table__body__item__column">{{item.bkdchgd}}</td>
            <td class="table__body__item__column">{{item.status}}</td>
            <td class="table__body__item__column" v-html="item.changedfields"></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="billo__pages" v-if="maxPages > 1">
        <button class="button" v-if="maxPages>1" :class="{'button--disabled':currentPage===1}"
                @click="currentPage--">{{ $t('previous') }}
        </button>
        <button class="button" v-if="maxPages>1" :class="{'button--disabled':currentPage===maxPages}"
                @click="currentPage++">
          {{ $t('next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vip",
  data(){
    return {
      currentPage: 1,
      pageSize: 25,
      searchVip: {
        PO: ""
      }
    }
  },

  created() {
    if(!this.hasVip){
      this.$router.replace('/');
      return;
    }
    this.getOrders();
  },

  computed: {
    hasVip() {
      return this.$store.getters['user/hasVip'];
    },

    orders() {
      return this.$store.getters['vip/orders'];
    },
    hasOrders() {
      return this.$store.getters['vip/hasOrders'];
    },
    maxPages() {
      return this.$store.getters['vip/pages'];
    }
  },

  watch: {
    currentPage() {
      this.getOrders();
    }
  },

  methods: {

    searchClicked() {
      this.currentPage = 1;
      this.getOrders();
    },
    resetClicked() {
      this.searchVip = {
        PO: ""
      }
      this.$store.dispatch('vip/clearOrders');
      this.searchClicked();
    },

    async getOrders(){
      try {
        await this.$store.dispatch('vip/getOrders', {
          PageSize: this.pageSize,
          PageNumber: this.currentPage,
          query: this.searchVip
        });
      } catch (e) {
        alert(e);
      }
    },

    openVip(po) {
      this.$router.push(`/vip/${po}`);
    }
  }
}
</script>