<template>
  <div id="app">
    <Header @open-incentive="isIncentiveVisible=true"></Header>
    <router-view/>
    <SubAccountLogin v-if="isAuthenticated && hasSubAccounts && !activeSubAccount"></SubAccountLogin>
    <IncentiveModal v-if="isIncentiveVisible" @on-close="isIncentiveVisible=false"></IncentiveModal>
  </div>
</template>
<script>
import Header from "@/components/structure/Header";
import SubAccountLogin from "@/components/user/SubAccountLogin";
import IncentiveModal from "@/components/user/IncentiveModal";
export default {
  data(){
    return {
      versionTimeout: null,
      isIncentiveVisible: false
    }
  },

  components: {
    Header,
    SubAccountLogin,
    IncentiveModal
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    hasSubAccounts() {
      return this.$store.getters['user/hasSubAccounts'];
    },
    activeSubAccount(){
      return this.$store.getters['user/activeSub'];
    }
  },

  created() {
    this.initVersionWatcher();
  },

  beforeDestroy(){
    clearTimeout(this.versionTimeout);
  },

  methods: {
    initVersionWatcher() {
      const current = parseInt(process.env.VERSION.replaceAll(".",""));
      const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/version?name=BILLOTRAVELAGENTS`;
      fetch(url).then(res => res.json().then(data => {
        if(data.status && data.data && data.data.versie){
          const latest = parseInt(data.data.versie.replaceAll(".",""));
          if(latest > current && data.data.hotfix){
            console.log('update required');
            location.reload();
          }
        }
        else{
          console.warn("could not fetch active version.");
        }
      }));
      this.versionTimeout = setTimeout(()=>{
        this.initVersionWatcher();
      }, 15*60*1000);
    }
  }
}
</script>
<style lang="scss">
 @import '../src/assets/sass/app';
</style>
