<template>
  <div class="modal modal--inc">
    <div class="modal__wrapper" v-if="!isLoading">
      <div class="modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </div>
      <h1>Incentive detail</h1>
      <div class="modal__body" v-if="incentiveData">
        <ul>
          <li v-for="item in Object.keys(incentiveData.metadata)" :key="item">
            {{item}}: <b>{{incentiveData.metadata[item]}}</b>
          </li>
        </ul>
        <div class="modal__result modal__result--incentive">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">{{ $t('data.date') }}</th>
            <th class="table__header__column">Type</th>
            <th class="table__header__column">Code</th>
            <th class="table__header__column">Incentive</th>
          </tr>
          </thead>
          <tbody class="table__body">
            <tr class="table__body__item" v-for="(item,index) in incentiveData.data"
                 :key="item.sortdatum+index">
              <td class="table__body__item__column">{{item.datum}}</td>
              <td class="table__body__item__column">{{item.soort}}</td>
              <td class="table__body__item__column">{{ item.code }}</td>
              <td class="table__body__item__column">{{ item.bedrag }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
    <fa-icon v-else class="loader" icon="spinner" spin></fa-icon>
  </div>
</template>

<script>
export default {
  name: "IncentiveModal",

  data(){
    return {
      isLoading: false,
      incentiveData: null
    };
  },

  created(){
    this.getIncentive();
  },

  methods:{
    async getIncentive(){
      this.isLoading = true;
      try{
        this.incentiveData = await this.$store.dispatch('user/getIncentiveData');
      }catch(e){
        alert(e);
      }
      this.isLoading = false;
    }
  }
}
</script>