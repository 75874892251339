<template>
  <header class="header" :class="{'header--auth':isAuthenticated}">
    <div>
      <img class="header__logo" src="../../assets/img/logo-billo.svg" alt="Billo logo">
    </div>
    <div class="header__nav" v-if="isAuthenticated">
      <router-link class="header__nav__item" to="/management" v-if="hasManagement">{{
          $t('home.menu.management')
        }}
      </router-link>
      <router-link class="header__nav__item" to="/pegase" v-if="hasPegase">Pegase</router-link>
      <a class="header__nav__item" target="_blank" :href="billoUrl" v-if="!hasPegase">{{ $t('home.menu.new') }}</a>
      <router-link class="header__nav__item" to="/billo" v-if="!hasPegase">{{ $t('home.menu.overview') }}</router-link>
      <router-link class="header__nav__item" to="/free" v-if="hasFree">{{ $t('home.menu.free') }}</router-link>
      <router-link class="header__nav__item" to="/groups" v-if="hasGroups">{{ $t('home.menu.groups') }}</router-link>
      <router-link class="header__nav__item" to="/vip" v-if="hasVip">{{ $t('home.menu.vip') }}</router-link>
      <router-link class="header__nav__item" to="/commissions" v-if="hasCommissions">{{
          $t('home.menu.commission')
        }}
      </router-link>
      <router-link class="header__nav__item" to="/messages">{{ $t('home.menu.messages') }}</router-link>
    </div>
    <div class="header__controls">
      <template v-if="isAuthenticated  && activeTalic">
        <div class="header__controls__user">
      <span v-if="activeSubAccount">
        <span>{{ activeTalic.TANAAM }} ({{ activeTalic.TALIC }}) - {{ activeSubAccount.voornaam }}</span>
        <p v-if="activeSubAccount && hasFree">
          Bonus: <b class="incentive" @click="showIncentive">
          €{{ Number(activeSubAccount.incentive).toFixed(2) }}</b>
        </p>
      </span>
          <span v-else>{{ activeTalic.TANAAM }} ({{ activeTalic.TALIC }})</span>
        </div>
      </template>

      <LanguageSelector></LanguageSelector>
      <button class="back" v-if="activeSubAccount" @click="logoutSub">
        <fa-icon icon="people-arrows"></fa-icon>
      </button>
      <button class="header__logout logout" @click="logout" title="log out" v-if="isAuthenticated">
        <fa-icon icon="power-off"></fa-icon>
      </button>

    </div>
  </header>
</template>

<script>
import LanguageSelector from "@/components/LanguageSelector";

export default {
  name: "Header",
  components: {
    LanguageSelector
  },

  computed: {
    activeSubAccount() {
      return this.$store.getters['user/activeSub'];
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    activeTalic() {
      return this.$store.getters['user/talicData'];
    },
    billoUrl() {
      if(!this.activeTalic){
        return;
      }
      return process.env.VUE_APP_BOOKING_ROOT + "?TALIC=" + this.activeTalic.TALIC + "&UID=" + (this.activeSubAccount ? this.activeSubAccount.uid : "") + "&TAEMAIL=" + this.activeTalic.TAEMAIL + "&TANAME=" + this.activeTalic.TANAAM + "&AGENT=true";
    },

    hasManagement() {
      return this.$store.getters['user/hasManagement'];
    },
    hasPegase() {
      return this.$store.getters['user/hasPegase'];
    },
    hasFree() {
      return this.$store.getters['user/hasFree'];
    },
    hasGroups() {
      return this.$store.getters['user/hasGroups'];
    },
    hasVip() {
      return this.$store.getters['user/hasVip'];
    },
    hasCommissions() {
      return this.$store.getters['user/hasCommissions'];
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    },
    logoutSub() {
      this.$store.dispatch('user/logoutSub');
    },
    showIncentive() {
      this.$emit('open-incentive');
    }
  }
}
</script>