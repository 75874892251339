export default{
    async getMessages(context){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/readmessages?talic=${talic}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch messages.'
            );
            throw error;
        }
        context.commit('setMessages', responseData.data);
    }
}