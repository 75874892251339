export default{
    async getUsers(context){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leesmedewerkers?talic=${talic}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch users.'
            );
            throw error;
        }
        context.commit('setUsers', responseData.data);
    },

    async addUser(context, payload){
        const token = context.rootGetters.token;
        const data = {};
        Object.keys(payload).forEach(key => data[key.toLowerCase()]=payload[key]);
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/newmedewerker?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to add user.'
            );
            throw error;
        }
        context.dispatch('getUsers');
    },
    async editUser(context, payload){
        const token = context.rootGetters.token;
        const data = {};
        Object.keys(payload).forEach(key => data[key.toLowerCase()]=payload[key]);
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/savemedewerker?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit user.'
            );
            throw error;
        }
        context.dispatch('getUsers');
    },

    async saveInfo(context, payload){
        const token = context.rootGetters.token;
        const talic = context.rootGetters.talic;
        payload.talic = talic;
        const data = {};

        Object.keys(payload).forEach(key => data[key.toLowerCase()]=payload[key]);

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/saveprofile?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to save talic info.'
            );
            throw error;
        }
        context.dispatch('user/getTalicData',null,{root:true});
    }
}