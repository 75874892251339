<template>
  <div class="page billodetail">
    <h1>{{ $t('home.menu.groups') }}</h1>
    <div class="page__content">
      <div class="billodetail__controls">
        <input type="text" class="input input--bordered"
               @keypress.enter.prevent="getOrder"
               placeholder="PO" v-model="search">
        <button class="button" @click="getOrder">PO {{ $t('search') }}</button>
      </div>
      <div class="billodetail__data" v-if="orderData">
        <p class="success" v-if="orderSuccess">{{ $t('groups.success') }}</p>
        <template v-else>
          <div class="billodetail__data__trip">
            <h2>{{ $t('input.personal') }}</h2>
            <form action="" @submit.prevent="" class="billodetail__data__trip__form">
              <div class="billodetail__data__trip__form__column">
                <label>{{ $t('input.fname') }}</label>
                <input type="text" class="input input--bordered" v-model="input.voornaam">
                <label>{{ $t('input.name') }}</label>
                <input type="text" class="input input--bordered" v-model="input.naam">
                <label>{{ $t('input.mobile') }}</label>
                <input type="tel" class="input input--bordered" v-model="input.tel">
                <label>{{ $t('input.email') }}</label>
                <input type="email" class="input input--bordered" v-model="input.email">
                <label>{{ $t('input.lang') }}</label>
                <select class="select" v-model="input.taal">
                  <option value="NL">NL</option>
                  <option value="FR">FR</option>
                </select>
              </div>
            </form>
          </div>
          <div class="billodetail__data__trip">
            <h2>{{ $t('input.departure') }}</h2>
            <form action="" @submit.prevent="" class="billodetail__data__trip__form">
              <div class="billodetail__data__trip__form__column">
                <label>{{ $t('input.date') }}</label>
                <input type="text" class="input input--bordered" readonly :value="`${ orderData.DATUMHEEN } (${ orderData.UURHEEN })`">
                <label>{{ $t('input.flight') }}</label>
                <input type="text" class="input input--bordered" readonly :value="`${ orderData.LUCHTHAVENHEEN } (${ orderData.VLUCHTNRHEEN })`">
                <label>{{ $t('input.marginap') }}</label>
                <select class="select" v-model="input.margin">
                  <option value="2">2u</option>
                  <option value="2.5">2u 30min</option>
                  <option value="3">3u</option>
                </select>
                <label>{{ $t('input.street') }}</label>
                <input type="text" :placeholder="$t('input.street')" class="input input--bordered"
                       v-model="input.addressH.street">
                <label>{{ $t('input.nr') }}</label>
                <input type="text" :placeholder="$t('input.nr')" class="input input--bordered small"
                       v-model="input.addressH.nr">
                <label>{{ $t('input.zip') }}</label>
                <input type="text" :placeholder="$t('input.zip')" class="input input--bordered medium"
                       v-model="input.addressH.zip">
                <label>{{ $t('input.city') }}</label>
                <input type="text" :placeholder="$t('input.city')" class="input input--bordered"
                       v-model="input.addressH.city">
                <label>{{ $t('input.country') }}</label>
                <select class="select" v-model="input.addressH.country">
                  <option value="BE">{{ $t('input.countries.be') }}</option>
                  <option value="NL">{{ $t('input.countries.nl') }}</option>
                  <option value="FR">{{ $t('input.countries.fr') }}</option>
                  <option value="LU">{{ $t('input.countries.lu') }}</option>
                </select>
              </div>
            </form>
          </div>
          <div class="billodetail__data__trip">
            <h2>{{ $t('input.arrival') }}</h2>
            <form action="" @submit.prevent="" class="billodetail__data__trip__form">
              <div class="billodetail__data__trip__form__column">
                <label>{{ $t('input.date') }}</label>
                <input type="text" class="input input--bordered" readonly :value="`${ orderData.DATUMRETOUR } (${ orderData.UURRETOUR })`">
                <label>{{ $t('input.flight') }}</label>
                <input type="text" class="input input--bordered" readonly :value="`${ orderData.RETOURHERKOMST } => ${ orderData.LUCHTHAVENRETOUR } (${orderData.VLUCHTNRRETOUR})`">
                <label>{{ $t('input.street') }}</label>
                <input type="text" :placeholder="$t('input.street')" class="input input--bordered"
                       v-model="input.addressT.street">
                <label>{{ $t('input.nr') }}</label>
                <input type="text" :placeholder="$t('input.nr')" class="input input--bordered small"
                       v-model="input.addressT.nr">
                <label>{{ $t('input.zip') }}</label>
                <input type="text" :placeholder="$t('input.zip')" class="input input--bordered medium"
                       v-model="input.addressT.zip">
                <label>{{ $t('input.city') }}</label>
                <input type="text" :placeholder="$t('input.city')" class="input input--bordered"
                       v-model="input.addressT.city">
                <label>{{ $t('input.country') }}</label>
                <select class="select" v-model="input.addressT.country">
                  <option value="BE">{{ $t('input.countries.be') }}</option>
                  <option value="NL">{{ $t('input.countries.nl') }}</option>
                  <option value="FR">{{ $t('input.countries.fr') }}</option>
                  <option value="LU">{{ $t('input.countries.lu') }}</option>
                </select>
              </div>
            </form>
          </div>
          <div class="billodetail__data__trip">
            <h2 :class="{'error':cargo>MAX_CARGO}">Cargo ({{ cargo }}/{{ MAX_CARGO }})</h2>
            <form action="" @submit.prevent="" class="billodetail__data__trip__form">
              <div class="billodetail__data__trip__form__column">
                <label for="grpax">Pax</label>
                <input id="grpax" type="number" class="input input--bordered input--nr" min="1" max="8" v-model="input.cargo.pax"
                       @focusout="handleNumberInput($event,'pax')">
                <label for="grsc">{{ $t('input.suitcases') }}</label>
                <input id="grsc" type="number" class="input input--bordered input--nr" min="0" max="8" v-model="input.cargo.koffers"
                       @focusout="handleNumberInput($event,'koffers')">
                <label for="grski">{{ $t('input.skis') }}</label>
                <input id="grski" type="number" class="input input--bordered input--nr" min="0" max="8" v-model="input.cargo.skis"
                       @focusout="handleNumberInput($event,'skis')">
                <label for="grgb">{{ $t('input.golfbags') }}</label>
                <input id="grgb" type="number" class="input input--bordered input--nr" min="0" max="8" v-model="input.cargo.golfbags"
                       @focusout="handleNumberInput($event,'golfbags')">
                <label for="grbike">{{ $t('input.bike') }}</label>
                <input id="grbike" type="number" class="input input--bordered input--nr" min="0" max="3"
                       v-model="input.cargo.fietskoffers" @focusout="handleNumberInput($event,'fietskoffers')">
                <label for="grstroll">{{ $t('input.strollers') }}</label>
                <input id="grstroll" type="number" class="input input--bordered input--nr" min="0" max="8"
                       v-model="input.cargo.kinderwagens" @focusout="handleNumberInput($event,'kinderwagens')">
                <label for="grage1">{{ $t('input.age1') }}</label>
                <input id="grage1" type="number" class="input input--bordered input--nr" min="0" max="2" v-model="input.cargo.aantalks2"
                       @focusout="handleNumberInput($event,'aantalks2')">
                <label for="grage2">{{ $t('input.age2') }}</label>
                <input id="grage2" type="number" class="input input--bordered input--nr" min="0" max="2" v-model="input.cargo.aantalks3"
                       @focusout="handleNumberInput($event,'aantalks3')">
                <label for="grage3">{{ $t('input.age3') }}</label>
                <input id="grage3" type="number" class="input input--bordered input--nr" min="0" max="2" v-model="input.cargo.aantalks4"
                       @focusout="handleNumberInput($event,'aantalks4')">
                <label for="grwc">{{ $t('input.wheelchairs') }}</label>
                <input id="grwc" type="number" class="input input--bordered input--nr" min="0" max="3" v-model="input.cargo.rolstoelen"
                       @focusout="handleNumberInput($event,'rolstoelen')">
                <label for="grrol">{{ $t('input.rollators') }}</label>
                <input id="grrol" type="number" class="input input--bordered input--nr" min="0" max="3" v-model="input.cargo.rollators"
                       @focusout="handleNumberInput($event,'rollators')">
              </div>
            </form>
          </div>
          <div class="billodetail__data__trip">
            <h2>{{ $t('input.price') }}</h2>
            <form action="" @submit.prevent="" class="billodetail__data__trip__form">
              <div class="billodetail__data__trip__form__column">
                <label>{{ $t('input.priceperson') }} (€)</label>
                <input type="text" class="input input--bordered" readonly v-model="orderData.PRIJSPERPERSOON">
                <label>{{ $t('input.pricetotal') }} (€)</label>
                <input type="text" class="input input--bordered" readonly v-model="totalPrice">
              </div>
            </form>
            <button class="button button--accept" :class="{'button--disabled':isSaving}" @click="submitOrder">
              {{ $t('input.createbooking') }}
            </button>
            <p class="error" v-if="orderError">{{ $t(orderError) }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {ObjectMixin} from "@/mixins/ObjectMixin";

export default {
  name: "Groups",

  data() {
    return {
      search: "",
      orderData: null,
      isSaving: false,
      MAX_CARGO: 16,
      input: {},
      orderSuccess: false,
      orderError: null
    }
  },

  created() {
    if (!this.hasGroups) {
      this.$router.replace('/');
    }
  },
  computed: {
    hasGroups() {
      return this.$store.getters['user/hasGroups'];
    },

    talicData(){
      return this.$store.getters['user/talicData'];
    },

    subAccount(){
      return this.$store.getters['user/activeSub']
    },

    totalPrice() {
      return this.orderData ? parseFloat(this.orderData.PRIJSPERPERSOON) * parseInt(this.input.cargo.pax) * 2 : 0;
    },

    totalKs() {
      return this.orderData ? parseInt(this.input.cargo.aantalks1) + parseInt(this.input.cargo.aantalks2) + parseInt(this.input.cargo.aantalks3) + parseInt(this.input.cargo.aantalks4):0;
    },

    cargo() {
      return this.orderData ? parseInt(this.input.cargo.pax) + parseInt(this.input.cargo.koffers) + parseInt(this.input.cargo.rolstoelen) * 5 + parseInt(this.input.cargo.skis) * 2 + parseInt(this.input.cargo.golfbags) * 2 + parseInt(this.input.cargo.rollators) * 5 + parseInt(this.input.cargo.fietskoffers) * 5 + parseInt(this.input.cargo.kinderwagens) * 2:0;
    }
  },

  methods: {
    async getOrder() {
      if (!this.search) {
        return;
      }
      try {
        this.orderData = await this.$store.dispatch('groups/getOrder', this.search);
        this.orderSuccess = false;
        this.setupInput();
      } catch (e) {
        alert(e);
      }
    },
    setupInput() {
      this.input = {
        po: this.orderData.PO,
        taal: "NL",
        naam: "",
        voornaam: "",
        email: "",
        tel: "",
        addressH: {
          street: '',
          nr: '',
          zip: '',
          city: '',
          country: 'BE'
        },
        addressT: {
          street: '',
          nr: '',
          zip: '',
          city: '',
          country: 'BE'
        },
        cargo: {
          pax: 1,
          koffers: 0,
          rolstoelen: 0,
          skis: 0,
          kinderwagens: 0,
          golfbags: 0,
          fietskoffers: 0,
          rollators: 0,
          aantalks1: 0,
          aantalks2: 0,
          aantalks3: 0,
          aantalks4: 0
        },
        margin: "2"
      }
    },

    handleNumberInput(event, key) {
      const max = parseInt(event.target.getAttribute('max'));
      const min = parseInt(event.target.getAttribute('min'));

      let value = event.target.value;
      if (!parseInt(value)) {
        this.input.cargo[key] = min;
      } else {
        if (parseInt(value) > max) {
          this.input.cargo[key] = max;
        }
        if (parseInt(value) < min) {
          this.input.cargo[key] = min;
        }
      }
    },

    checkValid() {
      this.orderError = null;
      if (!this.input.voornaam || !this.input.naam || !this.input.email || !this.input.tel) {
        this.orderError = 'input.errorpersonal'
        return false;
      }
      if (this.cargo > this.MAX_CARGO || !this.cargo) {
        this.orderError = 'input.errorcargo';
        return false;
      }
      if (this.totalKs > 2) {
        this.orderError = 'input.errorhc';
        return false;
      }
      return true;
    },

    async submitOrder() {
      const cargo = this.input.cargo;
      const heen = this.input.addressH;
      const terug = this.input.addressT;
      const po = this.orderData;
      this.isSaving = true;
      if(!this.checkValid()){
        this.isSaving = false;
        return;
      }
      try{
        await this.$store.dispatch('groups/checkAddress', heen);
      }
      catch(e){
        this.orderError = 'input.errorfrom';
        this.isSaving = false;
        return;
      }
      try{
        await this.$store.dispatch('groups/checkAddress', terug);
      }
      catch(e){
        this.orderError = 'input.errorto';
        this.isSaving = false;
        return;
      }

        const data = {
          po: po.PO,
          talic: this.talicData.TALIC,
          tanaam: this.talicData.TANAAM,
          taemail: this.talicData.TAEMAIL,
          tatel: this.talicData.TATEL,
          taal: this.input.taal,
          naam: this.input.naam,
          voornaam: this.input.voornaam,
          email: this.input.email,
          tel: this.input.tel,
          uid: this.activeSub?this.activeSub.uid:this.talicData.TALIC,
          pax: cargo.pax,
          koffers: cargo.koffers,
          rolstoelen: cargo.rolstoelen,
          skis: cargo.skis,
          kinderwagens: cargo.kinderwagens,
          golfbags: cargo.golfbags,
          fietskoffers: cargo.fietskoffers,
          rollators: cargo.rollators,
          aantalks1: cargo.aantalks1,
          aantalks2: cargo.aantalks2,
          aantalks3: cargo.aantalks3,
          aantalks4: cargo.aantalks4,
          datumheen: ObjectMixin.formatDateSql(po.DATUMHEEN),
          vluchtnrheen: po.VLUCHTNRHEEN,
          uurheen: po.UURHEEN,
          luchthavenheen: po.LUCHTHAVENHEEN,
          luchthavenmarge: this.input.margin,
          straatheen: heen.street,
          nrheen: heen.nr,
          postcodeheen: heen.zip,
          gemeenteheen: heen.city,
          landheen: heen.country,
          datumterug: ObjectMixin.formatDateSql(po.DATUMRETOUR),
          vluchtnrterug: po.VLUCHTNRRETOUR,
          uurterug: po.UURRETOUR,
          luchthaventerug: po.LUCHTHAVENRETOUR,
          luchthavenherkomst: po.RETOURHERKOMST,
          straatterug: terug.street,
          nrterug: terug.nr,
          postcodeterug: terug.zip,
          gemeenteterug: terug.city,
          landterug: terug.country,
          prijsperpersoon: po.PRIJSPERPERSOON
        };
        try{
          await this.$store.dispatch('groups/saveOrder', data);
          this.orderSuccess = true;
          this.setupInput();
        }catch(e){
          this.orderError = 'input.errorbooking';
        }
        this.isSaving = false;
    }
  }
}
</script>