<template>
  <div class="page billo">
    <h1>Pegase</h1>
    <div class="page__content">
      <div class="billo__filter">
        <div>
          <label for="svippo">{{$t('input.po')}}</label>
          <input id="svippo" type="text" class="input input--bordered" v-model="searchVip.JUMPNR"
                 @keypress.enter="searchClicked">
        </div>
        <div>
          <button class="button" @click="searchClicked">{{ $t('search') }}</button>
          <button class="button button--cancel" @click="resetClicked">{{ $t('reset') }}</button>
        </div>
      </div>
      <div class="billo__data">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">{{ $t('data.ordernr') }}</th>
            <th class="table__header__column">{{$t('input.po')}}</th>
            <th class="table__header__column">{{ $t('data.date') }}</th>
            <th class="table__header__column">{{ $t('data.time') }}</th>
            <th class="table__header__column">{{$t('data.customer')}}</th>
            <th class="table__header__column">{{ $t('input.flight') }}</th>
            <th class="table__header__column">{{ $t('data.flighttime') }}</th>
            <th class="table__header__column">{{ $t('data.from') }}</th>
            <th class="table__header__column">{{ $t('data.to') }}</th>
            <th class="table__header__column">Pax</th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" @click="openPegase(item.BONNR)"
              :title="parseInt(item.geweigerd)?$t('vip.refused'):''"
              v-for="item in orders"
              :key="item.ID">
            <td class="table__body__item__column">{{ item.BONNR }}</td>
            <td class="table__body__item__column">{{ item.JUMPNR }}</td>
            <td class="table__body__item__column">{{ item.DATUM }}</td>
            <td class="table__body__item__column">{{ item.UUR}} {{item.UUR === '00:00'? `(${$t('data.timing')})`:""}}</td>
            <td class="table__body__item__column">{{ item.PASSAGIER }}</td>
            <td class="table__body__item__column">{{ item.VLUCHTNR }}</td>
            <td class="table__body__item__column">{{ item.UURVLUCHT }}</td>
            <td class="table__body__item__column">{{ item.VAN }}</td>
            <td class="table__body__item__column">{{ item.NAAR }}</td>
            <td class="table__body__item__column">{{ item.AANTALREIZIGERS }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="billo__pages" v-if="maxPages > 1">
        <button class="button" v-if="maxPages>1" :class="{'button--disabled':currentPage===1}"
                @click="currentPage--">{{ $t('previous') }}
        </button>
        <button class="button" v-if="maxPages>1" :class="{'button--disabled':currentPage===maxPages}"
                @click="currentPage++">
          {{ $t('next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pegase",
  data(){
    return {
      currentPage: 1,
      pageSize: 25,
      searchVip: {
        PO: ""
      }
    }
  },

  created() {
    if(!this.hasPegase){
      this.$router.replace('/');
      return;
    }
    this.getOrders();
  },

  computed: {
    hasPegase() {
      return this.$store.getters['user/hasPegase'];
    },

    orders() {
      return this.$store.getters['pegase/orders'];
    },
    hasOrders() {
      return this.$store.getters['pegase/hasOrders'];
    },
    maxPages() {
      return this.$store.getters['pegase/pages'];
    }
  },

  watch: {
    currentPage() {
      this.getOrders();
    }
  },

  methods: {

    searchClicked() {
      this.currentPage = 1;
      this.getOrders();
    },
    resetClicked() {
      this.searchVip = {
        JUMPNR: ""
      }
      this.$store.dispatch('pegase/clearOrders');
      this.searchClicked();
    },

    async getOrders(){
      try {
        await this.$store.dispatch('pegase/getOrders', {
          PageSize: this.pageSize,
          PageNumber: this.currentPage,
          query: this.searchVip
        });
      } catch (e) {
        alert(e);
      }
    },

    openPegase(jumpnr) {
      this.$router.push(`/pegase/${jumpnr}`);
    }
  }
}
</script>