<template>
  <div class="page commissions">
    <h1>{{ $t('home.menu.commission') }}</h1>
    <div class="page__content">
      <h2>{{ $t('commissions.nocom') }}</h2>
      <div class="commissions__data">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">{{ $t('data.year') }}</th>
            <th class="table__header__column">{{ $t('data.month') }}</th>
            <th class="table__header__column">{{ $t('data.amount') }}</th>
            <th class="table__header__column">{{ $t('data.turnover') }}</th>
            <th class="table__header__column">{{ $t('data.commission') }}</th>
            <th class="table__header__column">{{ $t('data.actions') }}</th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" v-for="item in openCommissions"
              :key="item.ID">
            <td class="table__body__item__column">{{ item.Year }}</td>
            <td class="table__body__item__column">{{ item.Month }}</td>
            <td class="table__body__item__column">{{ item.Total }}</td>
            <td class="table__body__item__column">{{ item.turnover }}</td>
            <td class="table__body__item__column">{{ item.commission }}</td>
            <td class="table__body__item__column">
              <button @click="showCommissionDetail(item.Year,item.Month)">{{ $t('data.detail') }}</button>
              <button v-if="checkDatePassed(item.Month,item.Year)" @click="createNota(item.Year,item.Month)">
                {{ $t('commissions.createnota') }}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <h2>{{ $t('commissions.unpaid') }}</h2>
      <div class="commissions__data">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">ID</th>
            <th class="table__header__column">{{ $t('data.date') }}</th>
            <th class="table__header__column">{{ $t('data.info') }}</th>
            <th class="table__header__column">{{ $t('data.turnover') }}</th>
            <th class="table__header__column">{{ $t('data.commission') }}</th>
            <th class="table__header__column">{{ $t('data.status') }}</th>
            <th class="table__header__column">{{ $t('data.actions') }}</th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" v-for="item in unpaidCommissions"
              :key="item.ID">
            <td class="table__body__item__column">{{ item.ID }}</td>
            <td class="table__body__item__column">{{ item.DATUM }}</td>
            <td class="table__body__item__column">{{ item.OMSCHRIJVING }}</td>
            <td class="table__body__item__column">{{ item.OMZET }}</td>
            <td class="table__body__item__column">{{ item.COMMISSIE }}</td>
            <td class="table__body__item__column">{{ item.STATUSBETALING }}</td>
            <td class="table__body__item__column">
              <button @click="showCommissionNotaDetail(item.ID)">{{ $t('data.detail') }}</button>
              <button @click="payNota(item.ID, item.COMMISSIE, item.OMSCHRIJVING)">
                {{ $t('commissions.pay') }}
              </button>
              <button @click="showNota(item.ID)">{{ $t('commissions.show') }}</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <h2>{{ $t('commissions.paid') }}</h2>
      <div class="commissions__data">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">ID</th>
            <th class="table__header__column">{{ $t('data.date') }}</th>
            <th class="table__header__column">{{ $t('data.info') }}</th>
            <th class="table__header__column">{{ $t('data.turnover') }}</th>
            <th class="table__header__column">{{ $t('data.commission') }}</th>
            <th class="table__header__column">{{ $t('data.ref') }}</th>
            <th class="table__header__column">{{ $t('data.actions') }}</th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" v-for="item in paidCommissions"
              :key="item.ID">
            <td class="table__body__item__column">{{ item.ID }}</td>
            <td class="table__body__item__column">{{ item.DATUM }}</td>
            <td class="table__body__item__column">{{ item.OMSCHRIJVING }}</td>
            <td class="table__body__item__column">{{ item.OMZET }}</td>
            <td class="table__body__item__column">{{ item.COMMISSIE }}</td>
            <td class="table__body__item__column">{{ item.REFBETALING }}</td>
            <td class="table__body__item__column">
              <button @click="showCommissionNotaDetail(item.ID)">{{ $t('data.detail') }}</button>
              <button @click="showNota(item.ID)">{{ $t('commissions.show') }}</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="modal modal--com" v-if="notaToCreate">
      <div class="modal__wrapper">
        <div class="modal__close" @click="notaToCreate=null">
          <fa-icon icon="times"></fa-icon>
        </div>
        <h1>Commissie nota aanmaken</h1>
        <div class="modal__body">
          <p>Zeker dat je de commissie nota voor {{ `${notaToCreate.month}/${notaToCreate.year}` }} wil
            aanmaken?</p>
        </div>
        <div class="modal__controls">
          <button class="button button--cancel" @click="notaToCreate=null">Nee</button>
          <button class="button button--accept" @click="createNotaAccept">Ja</button>
        </div>
      </div>
    </div>

    <div class="modal" v-if="commissionDetail">
      <div class="modal__wrapper">
        <div class="modal__close" @click="commissionDetail=null">
          <fa-icon icon="times"></fa-icon>
        </div>
        <h1>Commissie details</h1>
        <div class="modal__body">
          <p>Omzet: <b>€{{ commissionDetail.omzet }}</b></p>
          <p>Commissie: <b>€{{ commissionDetail.bedragcommissie }}</b></p>
          <div class="orders orders--comdetail">
            <table class="table">
              <thead>
              <tr class="table__header">
                <th class="table__header__column">Bonnr</th>
                <th class="table__header__column">Datum boeking</th>
                <th class="table__header__column">Datum rit</th>
                <th class="table__header__column">Prijs</th>
                <th class="table__header__column">Commissie</th>
                <th class="table__header__column">Traject</th>
              </tr>
              </thead>
              <tbody class="table__body">
                <tr class="table__body__item" v-for="item in commissionDetail.orders"
                     :key="'comorder--'+item.ID">
                  <td class="table__body__item__column">{{ item.BONNR }}</td>
                  <td class="table__body__item__column">{{ item.CREATIONDATE }}</td>
                  <td class="table__body__item__column">{{ item.DAT }}</td>
                  <td class="table__body__item__column">€{{ item.OPGAVEPRIJS }}</td>
                  <td class="table__body__item__column">€{{ item.COMMISSIE }}</td>
                  <td class="table__body__item__column">{{ item.VAN }}<br>{{ item.NAAR }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <PayModal v-if="activePayment" :data="activePayment" @payment-complete="getCommissions"
              @on-close="activePayment=null"></PayModal>

  </div>
</template>

<script>
import PayModal from "@/components/PayModal";
export default {
  name: "Commissions",

  components:{
    PayModal
  },

  data() {
    return {
      currentDate: new Date(),
      activePayment: null,
      commissionDetail: null,
      notaToCreate: null
    }
  },

  created() {
    if(!this.hasCommissions){
      this.$router.replace('/');
      return
    }
    this.getCommissions();
  },

  computed: {
    hasCommissions(){
      return this.$store.getters['user/hasCommissions'];
    },
    talicData(){
      return this.$store.getters['user/talicData'];
    },
    openCommissions() {
      return this.$store.getters['commission/openCommissions'];
    },
    unpaidCommissions() {
      return this.$store.getters['commission/unpaidCommissions'];
    },
    paidCommissions() {
      return this.$store.getters['commission/paidCommissions'];
    }
  },

  methods: {
    checkDatePassed(month, year) {
      return month < this.currentDate.getMonth() + 1 && year === this.currentDate.getFullYear() || year < this.currentDate.getFullYear();
    },

    async getCommissions() {
      try {
        await this.$store.dispatch('commission/getCommissions');
      } catch (e) {
        alert(e);
      }
    },

    createNota(year, month) {
      this.notaToCreate = {year, month};
    },

    async createNotaAccept() {
      try {
        await this.$store.dispatch('commission/createCommissionNota', this.notaToCreate);
        this.notaToCreate = null;
      } catch (e) {
        alert(e);
      }
    },

    async showCommissionDetail(year, month) {
      try {
        this.commissionDetail = await this.$store.dispatch('commission/getCommissionDetail', {year, month});
      } catch (e) {
        alert(e);
      }
    },
    async showCommissionNotaDetail(id) {
      try {
        this.commissionDetail = await this.$store.dispatch('commission/getCommissionNotaDetail', id);
      } catch (e) {
        alert(e);
      }
    },

    showNota(id) {
      const token = this.$store.getters.token;
      const link = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/templates/commissie2pdf.php?ID=${id}&access_token=${token}`;
      const encoded = escape(link);
      const dataLink = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pdf/generate_pdf.json?url=${encoded}&access_token=${token}`;
      window.open(dataLink);
    },

    payNota(id, amount, description) {
      const name = this.talicData.TANAAM;
      const email = this.talicData.TAEMAIL;
      this.activePayment = {id, amount, description, name, email, type: 'refund', target: 'COMMISSIENOTAS'};
    }
  }
}
</script>